import { useNavigate } from "react-router-dom";
import { routesPath } from "../../routes/routes";
import { useMenu } from "../../contexts/menuContext";

export const HeaderTopBar = () => {
  const navigate = useNavigate();
  const { openMobileMenu } = useMenu();

  return (
    <div className="header-top-bar">
      <div className="logo">
        <img
          src={"/static/img/Logo/Yachthub_Dark_Rectangle_1x_svg.svg"}
          alt="Logo YachtHub"
          title="Logo YachtHub"
          onClick={() => navigate(routesPath.HOME)}
        />
      </div>
      <div className="end-components">
        <a href="tel:+420 777 644 491" className="contact-phone">
          <i className="icon-phone"></i>+420 777 644 491
        </a>
        <div className="mobile-menu-button" onClick={openMobileMenu}>
          <div className="title">MENU</div> <i className="icon-menu"></i>
        </div>
      </div>
    </div>
  );
};
