export function findStringPosition(original: string, query: string) {
  const start = original.indexOf(query); // Najde první výskyt B v A
  if (start === -1) {
    return null; // B ve stringu A není
  }
  const end = start + query.length - 1; // Konec výskytu
  return { start, end }; // Vrátí pozice
}

export function removeDiacritics(str: string) {
  return str.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
}
