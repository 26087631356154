export const routesPath = {
  HOME: "/",
  SEARCH: "/pronajem-lodi",
  BOAT: "/lod",
  RESERVATION: "/rezervace",
  CAPTAIN_COURSES: "/kapitanske-kurzy",
  CRUISES: "/plavby",
  DESTINATIONS: "/destinace",
  ABOUT: "/o-nas",
  CONTACT: "/kontakt",
  INSURANCE: "/pojisteni",
  // IFrame
  IFRAME_HOME: "/iframe",
  IFRAME_SEARCH: "/iframe/search",
  IFRAME_BOAT: "/iframe/lod",
  IFRAME_RESERVATION: "/iframe/rezervace",
};

export const menuItems: MenuItem[] = [
  { id: 1, text: "Pronájem lodí", link: routesPath.SEARCH },
  { id: 2, text: "Kapitánské kurzy", link: routesPath.CAPTAIN_COURSES },
  { id: 3, text: "Plavby", link: routesPath.CRUISES },
  { id: 4, text: "Destinace", link: routesPath.DESTINATIONS },
  { id: 4, text: "Pojištění", link: routesPath.INSURANCE },
  { id: 5, text: "#YachtHub", link: routesPath.ABOUT },
  { id: 6, text: "Kontakt", link: routesPath.CONTACT },
];

export interface MenuItem {
  id: number;
  text: string;
  link: string;
}
