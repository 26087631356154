import { Menu } from "../menu/menu";
import "../layout.styles.scss";
import { HeaderTopBar } from "./headerTopBar";
import { MenuMobile } from "../menu/menuMobile";
import { useMenu } from "../../contexts/menuContext";

export const Header = () => {
  const { isMobileOpen } = useMenu();

  return (
    <header>
      <HeaderTopBar />
      <Menu />
      {isMobileOpen && <MenuMobile />}
    </header>
  );
};
