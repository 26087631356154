import { Helmet } from "react-helmet";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { Breadcrumb } from "../../components/Breadcrumb/Breadcrumb";
import { routesPath } from "../../routes/routes";
import { useCallback, useEffect, useState } from "react";
import { BoatDetail, BoatImage } from "../../types/entities/Boat.types";
import axios from "../../api/axios";
import { apiUrl } from "../../api/apiUrl";
import "./boatPage.styles.scss";
import { CountryIcon } from "../../components/CountryIcon/CountryIcon";
import { BoatEquipment } from "../../types/entities/Equipment.types";
import { BoatEquipmentList } from "./BoatEquipmentList";
import ImageGallery from "react-image-gallery";
import "react-image-gallery/styles/scss/image-gallery.scss";
import { Map } from "../../components/Map/Map";
import { TermWithMessage } from "../../types/entities/Term.types";
import { Button } from "../../components/Buttons/Button";
import { Moment } from "moment";
import { Loader } from "../../components/Loader/Loader";
import { WorldMap } from "../../components/WorldMap/WorldMap";
import { Modal } from "../../components/Modal/Modal";
import { ReservationBox } from "../../components/ReservationBox/ReservationBox";
import { BackButton } from "../../components/BackButton/BackButton";

export const BoatPage = () => {
  const { boatUrl, termId: urlTermId } = useParams();
  const location = useLocation();
  const isIFrame = location.pathname.split("/")[1] === "iframe";
  const navigate = useNavigate();

  const [boat, setBoat] = useState<BoatDetail | null>(null);
  const [equipment, setEquipment] = useState<BoatEquipment[] | null>(null);
  const [termWithMessage, setTermWithMessage] =
    useState<TermWithMessage | null>(null);
  const [termLoading, setTermLoading] = useState(false);
  const [mapModalVisible, setMapModalVisible] = useState(false);

  const fetchBoat = useCallback(async () => {
    const result = await axios.apiGet({
      url: `${apiUrl.BOAT_BY_URL}?url=${boatUrl}`,
    });

    if (result?.status === 200) {
      setBoat(result.data);
      setEquipment(
        result.data.equipment.filter(
          (x: BoatEquipment) =>
            x.isIncluded || (!x.isIncluded && x.equipment.showNotIncluded)
        )
      );
    }
  }, [boatUrl]);

  const fetchTerm = useCallback(
    async (
      termId: number | null,
      dateFrom: Moment | null,
      dateTo: Moment | null
    ) => {
      setTermLoading(true);
      let result = null;

      if (termId)
        result = await axios.apiGet({
          url: `${apiUrl.TERM_ITEM}?id=${termId}`,
        });
      else if (!dateFrom && !dateTo)
        result = await axios.apiGet({
          url: `${apiUrl.TERM_ITEM_AVALIABLE}?boatId=${boat?.id}`,
        });
      else
        result = await axios.apiGet({
          url: `${apiUrl.TERM_ITEM_BY_DATE}?boatId=${
            boat?.id
          }&from=${dateFrom?.format("yyyy-MM-DD")}&to=${dateTo?.format(
            "yyyy-MM-DD"
          )}`,
        });

      if (result?.status === 200) setTermWithMessage(result.data);
      setTermLoading(false);
    },
    [boat]
  );

  const handleDateChange = useCallback(
    (dateFrom?: Moment, dateTo?: Moment) => {
      if (dateFrom && dateTo) fetchTerm(null, dateFrom, dateTo);
    },
    [boat]
  );

  useEffect(() => {
    fetchBoat();
  }, []);

  useEffect(() => {
    if (boat) fetchTerm(Number(urlTermId), null, null);
  }, [boat]);

  if (!boat) return <Loader text="Načítáme loď" />;
  if (!equipment) return <Loader text="Načítáme vybavení lodi" />;

  return (
    <>
      <Helmet>
        <title>YachtHub | {boat.name}</title>
      </Helmet>

      <div className="page">
        {!isIFrame ? (
          <Breadcrumb
            items={[
              { name: "Domů", path: routesPath.HOME },
              {
                name: "Pronájem lodí",
                path: routesPath.SEARCH,
              },
              {
                name: boat.name,
                path: routesPath.BOAT,
                isCurrent: true,
              },
            ]}
          />
        ) : (
          <BackButton
            onClick={() =>
              navigate(routesPath.IFRAME_SEARCH, {
                state: {
                  isFromBoatDetail: true,
                },
              })
            }
          />
        )}

        <div className="boat-page">
          <div className="left-column-boat-detail">
            <div className="header">
              <div className="left">
                <h1 className="name">{boat.name}</h1>
                <span className="boat-location">
                  <CountryIcon
                    iconName={boat.locationIcon}
                    countryName={boat.locationIcon}
                    style={{ marginRight: 10 }}
                  />
                  {boat.locationName}
                  <span className="dot"></span>
                  {boat.charterName}
                </span>
              </div>
              <div className="right">
                <WorldMap width={222} height={72}>
                  <Button
                    text="Zobrazit mapu"
                    size="small"
                    type="white"
                    icon="icon-globe"
                    iconPosition="start"
                    onClick={() => setMapModalVisible(true)}
                  />
                </WorldMap>
              </div>
            </div>
            <div className="image-slider">
              <ImageGallery
                items={boat.images.map((image: BoatImage) => {
                  return {
                    original: image.url,
                    originalHeight: 580,
                    thumbnail: image.url,
                  };
                })}
                showThumbnails
                thumbnailPosition="left"
                // renderLeftNav={(onClick: any) => {
                //   return (
                //     <CircleButton
                //       onClick={onClick}
                //       style="light"
                //       icon="icon-arrow_l"
                //       size="large"
                //       customClass="image-gallery-left-nav-custom"
                //     />
                //   );
                // }}
              />
            </div>
            <div className="right-column-reservation mobile">
              <ReservationBox
                termLoading={termLoading}
                termWithMessage={termWithMessage}
                boat={boat}
                isIFrame={isIFrame}
                handleDateChange={handleDateChange}
              />
            </div>
            <div className="technical-parameters">
              <h2>TECHNICKÉ ÚDAJE</h2>
              <div className="param-list">
                <ul className="column">
                  <li>
                    <div className="type">
                      <i className="icon-boat"></i>
                      Model
                    </div>
                    <div className="value">{boat.model}</div>
                  </li>
                  <li>
                    <div className="type">
                      <i className="icon-boat"></i>
                      Typ lodě
                    </div>
                    <div className="value">{boat.categoryName}</div>
                  </li>
                  <li>
                    <div className="type">
                      <i className="icon-built"></i>
                      Rok výroby
                    </div>
                    <div className="value">{boat.buildYear}</div>
                  </li>
                  <li>
                    <div className="type">
                      <i className="icon-submerge"></i>
                      Ponor
                    </div>
                    <div className="value">{boat.draught}</div>
                  </li>
                  <li>
                    <div className="type">
                      <i className="icon-width"></i>
                      Šířka
                    </div>
                    <div className="value">{boat.beam}</div>
                  </li>
                  <li>
                    <div className="type">
                      <i className="icon-length"></i>
                      Délka
                    </div>
                    <div className="value">{boat.length}</div>
                  </li>
                </ul>
                <ul className="column">
                  <li>
                    <div className="type">
                      <i className="icon-watertank"></i>
                      Nádrž na vodu
                    </div>
                    <div className="value">{boat.waterCapacity}</div>
                  </li>
                  <li>
                    <div className="type">
                      <i className="icon-gastank"></i>
                      Nádrž na palivo
                    </div>
                    <div className="value">{boat.fuelCapacity}</div>
                  </li>
                  <li>
                    <div className="type">
                      <i className="icon-engine"></i>
                      Motor
                    </div>
                    <div className="value">{boat.engine}</div>
                  </li>
                  <li>
                    <div className="type">
                      <i className="icon-wc"></i>
                      Počet WC
                    </div>
                    <div className="value">{boat.wc}</div>
                  </li>
                  <li>
                    <div className="type">
                      <i className="icon-avatar"></i>
                      Počet lůžek
                    </div>
                    <div className="value">{boat.berths}</div>
                  </li>
                  <li>
                    <div className="type">
                      <i className="icon-cabin"></i>
                      Počet kajut
                    </div>
                    <div className="value">{boat.cabins}</div>
                  </li>
                </ul>
              </div>
            </div>
            <div className="equipment">
              <h2>DALŠÍ PARAMETRY</h2>
              <div className="equipment-list">
                <BoatEquipmentList
                  equipment={equipment}
                  start={0}
                  end={Math.ceil(equipment.length / 2)}
                />
                <BoatEquipmentList
                  equipment={equipment}
                  start={Math.ceil(equipment.length / 2)}
                  end={equipment.length}
                />
              </div>
            </div>
            <div className="map">
              <h2>Místo vyzvednutí</h2>
              <span
                className="boat-location"
                style={{ marginTop: 15, marginBottom: 15 }}
              >
                <CountryIcon
                  iconName={boat.locationIcon}
                  countryName={boat.locationIcon}
                  style={{ marginRight: 10 }}
                />
                {boat.locationName}
              </span>
              <Map
                markers={[
                  {
                    lat: Number(boat.lat),
                    lng: Number(boat.lon),
                    port: {
                      name: boat.locationName,
                      icon: boat.locationIcon,
                      location: boat.locationName,
                    },
                  },
                ]}
                center={{ lat: Number(boat.lat), lng: Number(boat.lon) }}
                zoom={10}
                selectDefaultMarker
              />
            </div>
          </div>
          <div className="right-column-reservation desktop">
            <ReservationBox
              termLoading={termLoading}
              termWithMessage={termWithMessage}
              boat={boat}
              isIFrame={isIFrame}
              handleDateChange={handleDateChange}
            />
            {/* <BoatProducts products={boat.products} /> */}
          </div>
        </div>
      </div>
      <Modal
        visible={mapModalVisible}
        onClose={() => setMapModalVisible(false)}
      >
        <Map
          markers={[
            {
              lat: Number(boat.lat),
              lng: Number(boat.lon),
              port: {
                name: boat.locationName,
                icon: boat.locationIcon,
                location: boat.locationName,
              },
            },
          ]}
          center={{ lat: Number(boat.lat), lng: Number(boat.lon) }}
          zoom={10}
          height={"90vh"}
          selectDefaultMarker
        />
      </Modal>
    </>
  );
};
