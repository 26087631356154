import { useNavigate } from "react-router-dom";
import { CountryIcon } from "../../components/CountryIcon/CountryIcon";
import { Wave } from "../../components/Wave/Wave";
import { homepageCountries } from "../../types/entities/Country.types";
import "./homePage.styles.scss";
import { routesPath } from "../../routes/routes";
import {
  MultiSelectItemType,
  MultiSelectItemTypeName,
} from "../../types/inputs/MultiSelect.types";

export const CountriesContainer = () => {
  const navigate = useNavigate();
  return (
    <div className="countries-container">
      <div className="countries">
        <div className="headline">
          <div className="title">VYBER SI SVOU DESTINACI</div>
          <Wave count={12} color="gold" />
        </div>
        <div className="countries-list">
          {homepageCountries.map((country, i) => {
            return (
              <div
                key={i}
                className="country-background"
                style={{
                  backgroundImage: `url("/static/img/Destinations/${country.iconName.replace(
                    `.svg`,
                    `.jpg`
                  )}")`,
                }}
                onClick={() => {
                  navigate(routesPath.SEARCH, {
                    state: {
                      filterData: JSON.stringify({
                        multiSelectItemsCountries: [
                          {
                            id: country.id,
                            name: country.name,
                            parentName: null,
                            iconName: country.iconName,
                            type: MultiSelectItemType.COUNTRY,
                            typeName: MultiSelectItemTypeName.COUNTRY,
                            url: null,
                            boatsCount: 0,
                          },
                        ],
                        countries: [country.id],
                      }),
                    },
                  });
                }}
              >
                <div className="country">
                  <div className="title">
                    <CountryIcon
                      iconName={country.iconName}
                      countryName="croatia"
                      style={{
                        marginRight: 10,
                        width: 30,
                        border: `solid 2px white`,
                        borderRadius: `50%`,
                      }}
                    />
                    {country.name}
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};
