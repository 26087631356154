import { OrderBy } from "../../types/filter/filter.types";

export interface Props {
  orderBy: OrderBy;
  onChange: (orderBy: OrderBy) => void;
}

export const FilterOrderBy = ({ orderBy, onChange }: Props) => {
  return (
    <div className="filter-order-by">
      <nav>
        <button
          onClick={() => onChange(OrderBy.RECOMMENDED)}
          className={orderBy === OrderBy.RECOMMENDED ? "active" : ""}
        >
          NEJOBLÍBENĚJŠÍ
        </button>
        <button
          onClick={() => onChange(OrderBy.LARGEST_DISCOUNT)}
          className={orderBy === OrderBy.LARGEST_DISCOUNT ? "active" : ""}
        >
          NEJVĚTŠÍ SLEVA
        </button>
        <button
          onClick={() => onChange(OrderBy.CHEAPEST)}
          className={orderBy === OrderBy.CHEAPEST ? "active" : ""}
        >
          NEJNIŽŠÍ CENA
        </button>
        <button
          onClick={() => onChange(OrderBy.MOST_EXPENSIVE)}
          className={orderBy === OrderBy.MOST_EXPENSIVE ? "active" : ""}
        >
          NEJVYŠŠÍ CENA
        </button>
      </nav>
    </div>
  );
};
