export const apiUrl = {
  // *** Filter
  MULTI_SELECT_ITEM_LIST: `/MultiSelectItem/GetList`,
  MULTI_SELECT_ITEM_LIST_SELECTED: `/MultiSelectItem/GetListSelected`,
  SEARCH: `/Boat/GetListSearch`,

  // *** Boat detail
  BOAT_BY_URL: "/Boat/GetBoatByUrl",

  // *** Term
  TERM_ITEM: "/Term/GetItem",
  TERM_ITEM_AVALIABLE: "/Term/GetAvailableTerm",
  TERM_ITEM_BY_DATE: "/Term/GetItemByDate",
  TERM_LIST_SPECIAL_OFFERS: "/Term/GetSpecialOfferTerms",

  // *** Order
  ORDER_CREATE: "/Order/CreateOrder",
};
