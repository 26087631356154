import { useNavigate } from "react-router-dom";
import { Button } from "../../components/Buttons/Button";
import "./homePage.styles.scss";
import { routesPath } from "../../routes/routes";

export const InsuranceContainer = () => {
  const navigate = useNavigate();

  return (
    <div className="service-container">
      <div className="service">
        <div className="image">
          <img
            src="/static/img/Pics/pic_insure_1x_png.png"
            title="Pojištění obrázek"
            alt="Pojištění obrázek"
          />
        </div>
        <div className="content-container">
          <div className="content insurance">
            <div className="title">KOMPLETNÍ POJIŠTĚNÍ</div>
            <p>
              Sháníš pojištění pro svou plavbu? Rádi ti pomůžeme, neváhej nás
              kontaktovat.
            </p>
            <Button
              title="Zobrazit více - pojištění"
              text="Zobrazit více"
              type="primary"
              size="large"
              onClick={() => navigate(routesPath.INSURANCE)}
            />
          </div>
        </div>
      </div>
    </div>
  );
};
