import { LazyLoadImage } from "react-lazy-load-image-component";
import { BoatDetail } from "../../types/entities/Boat.types";
import { TermWithMessage } from "../../types/entities/Term.types";
import { CountryIcon } from "../../components/CountryIcon/CountryIcon";

interface Props {
  boat: BoatDetail;
  termWithMessage: TermWithMessage;
}

export const BoatInfo = ({ boat, termWithMessage }: Props) => {
  return (
    <div className="card">
      <h2>Informace o lodi</h2>
      <LazyLoadImage
        className="boat-image"
        alt={`${boat.name} - Úvodní fotografie`}
        title={`${boat.name} - Úvodní fotografie`}
        src={boat.mainImage}
        effect="blur"
      />
      <div className="boat-name">{boat.name}</div>
      <span className="boat-location">
        <CountryIcon
          iconName={boat.locationIcon}
          countryName={boat.locationIcon}
          style={{ marginRight: 10 }}
        />
        {boat.locationName}
      </span>
      <div className="date">
        <i className="icon-calendar"></i> {termWithMessage.term.date}
      </div>
    </div>
  );
};
