import "./worldMap.styles.scss";

interface Props {
  width?: number;
  height?: number;
  children: any;
}

export const WorldMap = ({
  width,
  height,
  children,
}: React.PropsWithChildren<Props>) => {
  const style = {
    width: width || "100%",
    height: height || "100%",
  };

  return (
    <div className="world-map" style={style}>
      {children}
    </div>
  );
};
