import "./buttons.styles.scss";

export interface Props {
  text?: string;
  title?: string;
  icon?: string;
  type?: "primary" | "light" | "white" | "gold" | "yellow";
  size?: "small" | "medium" | "large" | "full-width";
  customClass?: string;
  href: string;
}

export const ButtonLink = ({
  title,
  text,
  icon,
  type = "primary",
  size = "small",
  customClass,
  href,
}: Props) => {
  return (
    <a
      title={title}
      href={href}
      className={`button ${type} ${size}${
        customClass ? ` ${customClass}` : ""
      }`}
    >
      {text} <i className={icon}></i>
    </a>
  );
};
