import { useState } from "react";
import { Button } from "../../components/Buttons/Button";
import { Modal } from "../../components/Modal/Modal";
import { Wave } from "../../components/Wave/Wave";
import { WorldMap } from "../../components/WorldMap/WorldMap";
import { Map } from "../../components/Map/Map";
import { SearchFilterResult } from "../../types/filter/filter.types";
import { BoatSearch } from "../../types/entities/Boat.types";

interface Props {
  boatSearchResult: SearchFilterResult | null;
  loading: boolean;
  isMobile?: boolean;
}

export const SearchHeader = ({
  boatSearchResult,
  loading,
  isMobile,
}: Props) => {
  const [mapModalVisible, setMapModalVisible] = useState(false);

  return (
    <>
      <div className="header">
        <article>
          <h1>
            {(boatSearchResult && boatSearchResult.itemsCount > 0) || loading
              ? `Našli jsme pro tebe`
              : "Bohužel"}
          </h1>
          <span className="boat-count">
            {!loading &&
              (boatSearchResult && boatSearchResult.itemsCount > 0
                ? `${boatSearchResult.itemsCount.toLocaleString()} DOSTUPNÝCH LODÍ`
                : "Nenašli jsme pro tebe žádnou loď. Zkus upravit nastavení filtrů.")}
          </span>
          <Wave count={10} color="gold" style={{ marginTop: 15 }} />
          <p>
            Pro partu kamarádů, s rodinou nebo jen s tvoji drahou polovičkou? S
            rolovací nebo s klasickou hlavní plachtou? U nás si vybereš, ať je
            tvůj typ jakýkoliv. Vyber, rezervuj a vypluj za dobrodružstvím!
          </p>
        </article>
        <div className="map">
          {isMobile ? (
            <Button
              text="Zobrazit mapu"
              size="full-width"
              type="gold"
              icon="icon-globe"
              iconPosition="start"
              onClick={() => setMapModalVisible(true)}
            />
          ) : (
            <WorldMap>
              <Button
                text="Zobrazit mapu"
                size="medium"
                type="white"
                icon="icon-globe"
                iconPosition="start"
                onClick={() => setMapModalVisible(true)}
              />
            </WorldMap>
          )}
        </div>
      </div>
      <Modal
        visible={mapModalVisible}
        onClose={() => setMapModalVisible(false)}
      >
        <Map
          markers={
            boatSearchResult
              ? boatSearchResult.items.map((item: BoatSearch) => {
                  return {
                    lat: Number(item.lat),
                    lng: Number(item.lon),
                    port: {
                      name: item.locationName,
                      icon: item.locationIcon,
                      location: item.locationName,
                    },
                  };
                })
              : []
          }
          center={
            boatSearchResult && boatSearchResult.itemsCount > 0
              ? {
                  lat: Number(boatSearchResult.items[0].lat),
                  lng: Number(boatSearchResult.items[0].lon),
                }
              : { lat: 50.066806596224694, lng: 14.456522236673074 }
          }
          zoom={boatSearchResult && boatSearchResult.itemsCount > 0 ? 7 : 4}
          height={"90vh"}
        />
      </Modal>
    </>
  );
};
