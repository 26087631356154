export enum TermState {
  AVAILABLE,
  OPTION,
  BOOKED,
}

export interface TermWithMessage {
  term: Term;
  isAvaliable: boolean;
  message: string;
}

export interface Term {
  id: number;
  date: string;
  from: string;
  to: string;
  price: string;
  priceBefore: string;
  isDiscount: boolean;
  discountPercentage: string;
}
