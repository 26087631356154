import { MultiSelectItem } from "../../../types/inputs/MultiSelect.types";

export interface Props {
  selectedMultiSelectItems: MultiSelectItem[] | null;
  showResulsUnderInput?: boolean;
}

export const MultiSelectedInputSelected = ({
  selectedMultiSelectItems,
  showResulsUnderInput,
}: Props) => {
  if (!selectedMultiSelectItems || selectedMultiSelectItems.length === 0)
    return <></>;

  if (showResulsUnderInput)
    return (
      <div className="selected-values">
        <div className="counter">{selectedMultiSelectItems.length}</div>
      </div>
    );

  return (
    <div className="selected-values">
      {selectedMultiSelectItems.length > 1 && (
        <div className="counter with-plus">
          +{selectedMultiSelectItems.length - 1}
        </div>
      )}
      <div className="counter item-name">
        {selectedMultiSelectItems[0].name.split(" ")[0].substring(0, 7)}...
      </div>
    </div>
  );
};
