import { CircleButton } from "../../components/Buttons/CircleButton";
import "./fulscreenMobileContainer.styles.scss";

interface Props {
  title: string;
  icon?: string;
  children: any;
  onClose: () => void;
  isHidden?: boolean;
}

export const FulscreenMobileContainer = ({
  title,
  icon,
  children,
  onClose,
  isHidden,
}: React.PropsWithChildren<Props>) => {
  return (
    <div className={`fullscreen-mobile-container${isHidden ? " hidden" : ""}`}>
      <div className="mobile-header">
        <div className="title">
          <i className={icon}></i>
          {title}
        </div>
        <CircleButton
          title={`Zavřít`}
          icon="icon-x_medium"
          size="medium"
          style="light"
          onClick={onClose}
        />
      </div>
      <div className="mobile-content">{children}</div>
    </div>
  );
};
