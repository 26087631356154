import "./loader.styles.scss";

interface Props {
  text?: string;
  customClass?: string;
}

export const Loader = ({ text, customClass }: Props) => {
  return (
    <div className={`loader${customClass ? ` ${customClass}` : ""}`}>
      <img src="/static/img/Loader/loader.svg" alt="Loading"></img>
      <div className="text">{text && text}</div>
    </div>
  );
};
