import { DatePicker } from "../../components/inputs/DatePicker/DatePicker";
import {
  OrderBy,
  SearchFilterForm,
  boatCategories,
  defaultValues,
} from "../../types/filter/filter.types";
import moment, { Moment } from "moment";
import {
  MultiSelectItem,
  MultiSelectItemType,
} from "../../types/inputs/MultiSelect.types";
import { useCallback, useEffect, useState } from "react";
import { Slider } from "../../components/inputs/Slider/Slider";
import { Button } from "../../components/Buttons/Button";
import { MultiSelect2 } from "../../components/inputs/MultiSelect/MultiSelect2";

interface Props {
  defaultFilterData: SearchFilterForm;
  orderBy: OrderBy | null;
  isMobile?: boolean;
  onFilterDataChanged: (
    filterData: SearchFilterForm,
    orderBy?: OrderBy
  ) => void;
  onFilterDataChangedMobile?: (
    filterData: SearchFilterForm,
    orderBy?: OrderBy
  ) => void;
}

export const Filter = ({
  defaultFilterData,
  orderBy,
  isMobile,
  onFilterDataChanged,
  onFilterDataChangedMobile,
}: Props) => {
  const [filterData, setFilterData] = useState<SearchFilterForm>({
    ...defaultFilterData,
    dateFrom: defaultFilterData.dateFrom
      ? moment(defaultFilterData.dateFrom)
      : null,
    dateTo: defaultFilterData.dateFrom
      ? moment(defaultFilterData.dateTo)
      : null,
  });

  const handlePrimaryMultiSelectChange = useCallback(
    (selectedItems: MultiSelectItem[]) => {
      const countries = selectedItems.filter(
        (x) => x.type === MultiSelectItemType.COUNTRY
      );
      const regions = selectedItems.filter(
        (x) => x.type === MultiSelectItemType.REGION
      );
      const bases = selectedItems.filter(
        (x) => x.type === MultiSelectItemType.BASE
      );
      const charters = selectedItems.filter(
        (x) => x.type === MultiSelectItemType.CHARTER
      );

      const _filterData = {
        ...filterData,
        countries: countries.map((x) => x.id),
        regions: regions.map((x) => x.id),
        bases: bases.map((x) => x.id),
        charters: charters.map((x) => x.id),
        multiSelectItemsCountries: countries,
        multiSelectItemsRegions: regions,
        multiSelectItemsBases: bases,
        multiSelectItemsCharters: charters,
      };

      setFilterData(_filterData);
      onFilterDataChanged(_filterData, orderBy || undefined);
    },
    [filterData, orderBy]
  );

  const handleMultiSelectBoatCategoryChange = useCallback(
    (selectedItems: MultiSelectItem[]) => {
      const categories = selectedItems.filter(
        (x) => x.type === MultiSelectItemType.BOAT_CATEGORY
      );
      const _filterData = {
        ...filterData,
        boatCategories: categories.map((x) => x.id),
        multiSelectItemsBoatCategories: categories,
      };

      setFilterData(_filterData);
      onFilterDataChanged(_filterData, orderBy || undefined);
    },
    [filterData, orderBy]
  );

  const handleMultiSelectCountryChange = useCallback(
    (selectedItems: MultiSelectItem[]) => {
      const countries = selectedItems.filter(
        (x) => x.type === MultiSelectItemType.COUNTRY
      );
      const _filterData = {
        ...filterData,
        countries: countries.map((x) => x.id),
        multiSelectItemsCountries: countries,
      };

      setFilterData(_filterData);
      onFilterDataChanged(_filterData, orderBy || undefined);
    },
    [filterData, orderBy]
  );

  const handleMultiSelectRegionChange = useCallback(
    (selectedItems: MultiSelectItem[]) => {
      const regions = selectedItems.filter(
        (x) => x.type === MultiSelectItemType.REGION
      );
      const _filterData = {
        ...filterData,
        regions: regions.map((x) => x.id),
        multiSelectItemsRegions: regions,
      };

      setFilterData(_filterData);
      onFilterDataChanged(_filterData, orderBy || undefined);
    },
    [filterData, orderBy]
  );

  const handleMultiSelectBaseChange = useCallback(
    (selectedItems: MultiSelectItem[]) => {
      const bases = selectedItems.filter(
        (x) => x.type === MultiSelectItemType.BASE
      );
      const _filterData = {
        ...filterData,
        bases: bases.map((x) => x.id),
        multiSelectItemsBases: bases,
      };

      setFilterData(_filterData);
      onFilterDataChanged(_filterData, orderBy || undefined);
    },
    [filterData, orderBy]
  );

  const handleMultiSelectCharterChange = useCallback(
    (selectedItems: MultiSelectItem[]) => {
      const charters = selectedItems.filter(
        (x) => x.type === MultiSelectItemType.CHARTER
      );
      const _filterData = {
        ...filterData,
        charters: charters.map((x) => x.id),
        multiSelectItemsCharters: charters,
      };

      setFilterData(_filterData);
      onFilterDataChanged(_filterData, orderBy || undefined);
    },
    [filterData, orderBy]
  );

  const handleDateChange = useCallback(
    (dateFrom?: Moment, dateTo?: Moment) => {
      const _filterData = {
        ...filterData,
        dateFrom: dateFrom || null,
        dateTo: dateTo || null,
      };

      setFilterData(_filterData);
      onFilterDataChanged(_filterData, orderBy || undefined);
    },
    [filterData, orderBy]
  );

  const handleClearPrimary = useCallback(() => {
    const _filterData = {
      ...filterData,
      countries: [],
      regions: [],
      bases: [],
      charters: [],
      dateFrom: null,
      dateTo: null,
      multiSelectItemsCountries: [],
      multiSelectItemsRegions: [],
      multiSelectItemsBases: [],
      multiSelectItemsCharters: [],
    };

    setFilterData(_filterData);
    onFilterDataChanged(_filterData, orderBy || undefined);
  }, [filterData, orderBy]);

  const handleClearSecondary = useCallback(() => {
    const _filterData = {
      ...filterData,
      boatCategories: [],
      multiSelectItemsBoatCategories: [],
      berthsFrom: defaultValues.berthsFrom,
      berthsTo: defaultValues.berthsTo,
      priceFrom: defaultValues.priceFrom,
      priceTo: defaultValues.priceTo,
      lengthFrom: defaultValues.lengthFrom,
      lengthTo: defaultValues.lengthTo,
      yearOfManufactureFrom: defaultValues.yearOfManufactureFrom,
      yearOfManufactureTo: defaultValues.yearOfManufactureTo,
      cabinsFrom: defaultValues.cabinsFrom,
      cabinsTo: defaultValues.cabinsTo,
    };

    setFilterData(_filterData);
    onFilterDataChanged(_filterData, orderBy || undefined);
  }, [filterData, orderBy]);

  const handleClearSecondaryDestinations = useCallback(() => {
    const _filterData = {
      ...filterData,
      countries: [],
      regions: [],
      bases: [],
      charters: [],
      multiSelectItemsCountries: [],
      multiSelectItemsRegions: [],
      multiSelectItemsBases: [],
      multiSelectItemsCharters: [],
    };

    setFilterData(_filterData);
    onFilterDataChanged(_filterData, orderBy || undefined);
  }, [filterData, orderBy]);

  const handleClearAll = useCallback(() => {
    const _filterData = {
      ...filterData,
      boatCategories: [],
      multiSelectItemsBoatCategories: [],
      berthsFrom: defaultValues.berthsFrom,
      berthsTo: defaultValues.berthsTo,
      priceFrom: defaultValues.priceFrom,
      priceTo: defaultValues.priceTo,
      lengthFrom: defaultValues.lengthFrom,
      lengthTo: defaultValues.lengthTo,
      yearOfManufactureFrom: defaultValues.yearOfManufactureFrom,
      yearOfManufactureTo: defaultValues.yearOfManufactureTo,
      cabinsFrom: defaultValues.cabinsFrom,
      cabinsTo: defaultValues.cabinsTo,
      countries: [],
      regions: [],
      bases: [],
      charters: [],
      multiSelectItemsCountries: [],
      multiSelectItemsRegions: [],
      multiSelectItemsBases: [],
      multiSelectItemsCharters: [],
    };

    setFilterData(_filterData);
    onFilterDataChanged(_filterData, orderBy || undefined);
  }, [filterData, orderBy]);

  const handleBerthsChange = useCallback(
    (value: [number, number]) => {
      const _filterData = {
        ...filterData,
        berthsFrom: value[0],
        berthsTo: value[1],
      };

      setFilterData(_filterData);
      onFilterDataChanged(_filterData, orderBy || undefined);
    },
    [filterData, orderBy]
  );

  const handlePriceChange = useCallback(
    (value: [number, number]) => {
      const _filterData = {
        ...filterData,
        priceFrom: value[0],
        priceTo: value[1],
      };

      setFilterData(_filterData);
      onFilterDataChanged(_filterData, orderBy || undefined);
    },
    [filterData, orderBy]
  );

  const handleLengthChange = useCallback(
    (value: [number, number]) => {
      const _filterData = {
        ...filterData,
        lengthFrom: value[0],
        lengthTo: value[1],
      };

      setFilterData(_filterData);
      onFilterDataChanged(_filterData, orderBy || undefined);
    },
    [filterData, orderBy]
  );

  const handleYearOfManufactureChange = useCallback(
    (value: [number, number]) => {
      const _filterData = {
        ...filterData,
        yearOfManufactureFrom: value[0],
        yearOfManufactureTo: value[1],
      };

      setFilterData(_filterData);
      onFilterDataChanged(_filterData, orderBy || undefined);
    },
    [filterData, orderBy]
  );

  const handleCabinChange = useCallback(
    (value: [number, number]) => {
      const _filterData = {
        ...filterData,
        cabinsFrom: value[0],
        cabinsTo: value[1],
      };

      setFilterData(_filterData);
      onFilterDataChanged(_filterData, orderBy || undefined);
    },
    [filterData, orderBy]
  );

  useEffect(() => {
    if (!isMobile) onFilterDataChanged(filterData);
  }, []);

  return (
    <>
      <div className="primary-filter">
        <div className="filter-item">
          <MultiSelect2
            name={"primary-multiselect"}
            icon="icon-anchor"
            title="Destinace"
            placeholder="Kam popluješ?"
            type={null}
            defaultValues={[
              ...filterData.multiSelectItemsCountries,
              ...filterData.multiSelectItemsBases,
              ...filterData.multiSelectItemsRegions,
              ...filterData.multiSelectItemsCharters,
            ]}
            selectedEntities={[
              ...filterData.multiSelectItemsCountries,
              ...filterData.multiSelectItemsBases,
              ...filterData.multiSelectItemsRegions,
              ...filterData.multiSelectItemsCharters,
            ]}
            onChange={(selectedValues: MultiSelectItem[]) =>
              handlePrimaryMultiSelectChange(selectedValues)
            }
            resultsWidth={500}
            isMobile={window.innerWidth <= 585}
            showResulsUnderInput
          />
        </div>
        <div className="filter-item">
          <DatePicker
            name={"primary-datepicker"}
            defaultValues={{
              dateFrom: filterData.dateFrom,
              dateTo: filterData.dateTo,
            }}
            defaultActive={true}
            onChange={handleDateChange}
            icon="icon-calendar"
            isMobile={window.innerWidth <= 980}
          />
        </div>
        {!isMobile && (
          <Button
            text="Hledat"
            title="Hledat"
            size="large"
            icon="icon-search"
            customClass="primary-filter-button submit"
            onClick={() => onFilterDataChanged(filterData)}
          />
        )}
        <Button
          text="Smazat výběr"
          title="Smazat výběr"
          size="small"
          type="light"
          customClass="primary-filter-button clear"
          onClick={handleClearPrimary}
        />
      </div>
      <div className="secondary-filter">
        <div className="header">
          <div className="title">
            <i className="icon-filter"></i>Filtry
          </div>
          <Button
            text="Smazat"
            title="Smazat filtry"
            size="small"
            icon="icon-x_s"
            onClick={handleClearSecondary}
          />
        </div>
        <div className="filter-item">
          <MultiSelect2
            name={"multiselect-boat-category"}
            title="Typ lodě"
            placeholder="Vyber typ lodě"
            type={MultiSelectItemType.BOAT_CATEGORY}
            defaultValues={[...filterData.multiSelectItemsBoatCategories]}
            selectedEntities={[...filterData.multiSelectItemsBoatCategories]}
            onChange={handleMultiSelectBoatCategoryChange}
            resultsWidth={500}
            isMobile={window.innerWidth <= 585}
            isLocalDataSource
            localDataSource={boatCategories}
            showResulsUnderInput
          />
        </div>
        <div className="filter-item border">
          <Slider
            title="Lůžka"
            min={defaultValues.berthsFrom}
            max={defaultValues.berthsTo}
            defaultValues={[filterData.berthsFrom, filterData.berthsTo]}
            onChange={handleBerthsChange}
            disableMinPlus
          />
        </div>
        <div className="filter-item border">
          <Slider
            title="Kajuty"
            min={defaultValues.cabinsFrom}
            max={defaultValues.cabinsTo}
            defaultValues={[filterData.cabinsFrom, filterData.cabinsTo]}
            onChange={handleCabinChange}
            disableLocaleString
            disableMinPlus
          />
        </div>
        <div className="filter-item border">
          <Slider
            title="Cena"
            min={defaultValues.priceFrom}
            max={defaultValues.priceTo}
            unit="€"
            defaultValues={[filterData.priceFrom, filterData.priceTo]}
            onChange={handlePriceChange}
            disableMinPlus
          />
        </div>
        <div className="filter-item border">
          <Slider
            title="Délka"
            min={defaultValues.lengthFrom}
            max={defaultValues.lengthTo}
            unit="m"
            defaultValues={[filterData.lengthFrom, filterData.lengthTo]}
            onChange={handleLengthChange}
            disableLocaleString
            disableMinPlus
          />
        </div>
        <div className="filter-item border">
          <Slider
            title="Rok výroby"
            min={defaultValues.yearOfManufactureFrom}
            max={defaultValues.yearOfManufactureTo}
            defaultValues={[
              filterData.yearOfManufactureFrom,
              filterData.yearOfManufactureTo,
            ]}
            onChange={handleYearOfManufactureChange}
            disableLocaleString
            disableMaxPlus
          />
        </div>
      </div>
      <div className="secondary-filter second">
        <div className="header">
          <div className="title">
            <i className="icon-filter"></i>Destinace
          </div>
          <Button
            text="Smazat"
            title="Smazat filtry"
            size="small"
            icon="icon-x_s"
            onClick={handleClearSecondaryDestinations}
          />
        </div>
        <div className="filter-item">
          <MultiSelect2
            name={"multiselect-country"}
            title="Země"
            placeholder="Vyber zemi"
            type={MultiSelectItemType.COUNTRY}
            defaultValues={[
              ...filterData.multiSelectItemsCountries,
              ...filterData.multiSelectItemsRegions,
              ...filterData.multiSelectItemsBases,
              ...filterData.multiSelectItemsCharters,
            ]}
            selectedEntities={[
              ...filterData.multiSelectItemsCountries,
              ...filterData.multiSelectItemsBases,
              ...filterData.multiSelectItemsRegions,
              ...filterData.multiSelectItemsCharters,
            ]}
            onChange={(selectedValues: MultiSelectItem[]) =>
              handleMultiSelectCountryChange(selectedValues)
            }
            resultsWidth={500}
            isMobile={window.innerWidth <= 585}
            showResulsUnderInput
          />
        </div>
        <div className="filter-item">
          <MultiSelect2
            name={"multiselect-region"}
            title="Region"
            placeholder="Vyber region"
            type={MultiSelectItemType.REGION}
            defaultValues={[
              ...filterData.multiSelectItemsCountries,
              ...filterData.multiSelectItemsRegions,
              ...filterData.multiSelectItemsBases,
              ...filterData.multiSelectItemsCharters,
            ]}
            selectedEntities={[
              ...filterData.multiSelectItemsCountries,
              ...filterData.multiSelectItemsBases,
              ...filterData.multiSelectItemsRegions,
              ...filterData.multiSelectItemsCharters,
            ]}
            onChange={(selectedValues: MultiSelectItem[]) =>
              handleMultiSelectRegionChange(selectedValues)
            }
            resultsWidth={500}
            isMobile={window.innerWidth <= 585}
            showResulsUnderInput
          />
        </div>
        <div className="filter-item">
          <MultiSelect2
            name={"multiselect-base"}
            title="Přístav"
            placeholder="Vyber přístav"
            type={MultiSelectItemType.BASE}
            defaultValues={[
              ...filterData.multiSelectItemsCountries,
              ...filterData.multiSelectItemsRegions,
              ...filterData.multiSelectItemsBases,
              ...filterData.multiSelectItemsCharters,
            ]}
            selectedEntities={[
              ...filterData.multiSelectItemsCountries,
              ...filterData.multiSelectItemsBases,
              ...filterData.multiSelectItemsRegions,
              ...filterData.multiSelectItemsCharters,
            ]}
            onChange={(selectedValues: MultiSelectItem[]) =>
              handleMultiSelectBaseChange(selectedValues)
            }
            resultsWidth={500}
            isMobile={window.innerWidth <= 585}
            showResulsUnderInput
          />
        </div>
        <div className="filter-item">
          <MultiSelect2
            name={"multiselect-charter"}
            title="Charterová společnost"
            placeholder="Vyber společnost"
            type={MultiSelectItemType.CHARTER}
            defaultValues={[
              ...filterData.multiSelectItemsCountries,
              ...filterData.multiSelectItemsRegions,
              ...filterData.multiSelectItemsBases,
              ...filterData.multiSelectItemsCharters,
            ]}
            selectedEntities={[
              ...filterData.multiSelectItemsCountries,
              ...filterData.multiSelectItemsBases,
              ...filterData.multiSelectItemsRegions,
              ...filterData.multiSelectItemsCharters,
            ]}
            onChange={(selectedValues: MultiSelectItem[]) =>
              handleMultiSelectCharterChange(selectedValues)
            }
            resultsWidth={500}
            isMobile={window.innerWidth <= 585}
            showResulsUnderInput
          />
        </div>
      </div>
      {isMobile && (
        <div className="bottom-bar z-998 from-left">
          <Button
            text="Smazat vše"
            size="medium"
            type="light"
            onClick={() => handleClearAll()}
          />
          <Button
            text="Vyhledat"
            size="medium"
            type="primary"
            onClick={() =>
              onFilterDataChangedMobile && onFilterDataChangedMobile(filterData)
            }
          />
        </div>
      )}
    </>
  );
};
