import { useNavigate } from "react-router-dom";
import { Button } from "../../components/Buttons/Button";
import { Wave } from "../../components/Wave/Wave";
import "./homePage.styles.scss";
import { routesPath } from "../../routes/routes";

export const OfferContainer = () => {
  const navigate = useNavigate();

  return (
    <div className="offer-container">
      <div className="offers">
        <div className="offer-column left">
          <div className="offer-background left">
            <div className="offer">
              <div className="content">
                <div className="title">STAŇ SE KAPITÁNEM</div>
                <p>
                  Chtěl ses jako malý stát námořníkem? Svých snů se nevzdávej.
                  Můžeš si je splnit hned. Probuď v sobě kapitána!
                </p>
                <Button
                  title="Kapitánské kurzy"
                  text="Kapitánské kurzy"
                  size="medium"
                  type="white"
                  onClick={() => navigate(routesPath.CAPTAIN_COURSES)}
                />
              </div>
            </div>
          </div>
          <Wave
            count={10}
            color="gold"
            style={{ marginTop: 20, marginBottom: 20 }}
          />
        </div>
        <div className="offer-column right">
          <Wave
            count={10}
            color="gold"
            style={{ marginTop: 20, marginBottom: 20 }}
          />
          <div className="offer-background right">
            <div className="offer">
              <div className="content">
                <div className="title">VYPLUJ S NÁMI DO SVĚTA</div>
                <p>
                  Dobrodružství na moři? To chceš a nikdo tě nemusí přesvědčovat
                  dvakrát. Ať ho chceš zažít na expediční, kondiční nebo
                  zážitkové plavbě s námi, nebo si to odnaviguješ sám. S tím
                  vším umíme pomoct.
                </p>
                <Button
                  title="Aktuální plavby"
                  text="Aktuální plavby"
                  size="medium"
                  type="white"
                  onClick={() => navigate(routesPath.CRUISES)}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
