import { useNavigate } from "react-router-dom";
import { Button } from "../../components/Buttons/Button";
import { CountryIcon } from "../../components/CountryIcon/CountryIcon";
import { Tag } from "../../components/Tag/Tag";
import { BoatSearch } from "../../types/entities/Boat.types";
import { TermState } from "../../types/entities/Term.types";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";
import { routesPath } from "../../routes/routes";
import { Modal } from "../../components/Modal/Modal";
import { useState } from "react";
import { Map } from "../../components/Map/Map";

interface Props {
  boats: BoatSearch[];
  isIFrame: boolean;
}

export const SearchResults = ({ boats, isIFrame }: Props) => {
  const navigate = useNavigate();
  const [mapModalVisible, setMapModalVisible] = useState<BoatSearch | null>(
    null
  );

  return (
    <>
      <div className="search-result">
        {boats.map((boat, key) => {
          return (
            <div key={key} className="boat-card">
              <div className="main-image">
                <LazyLoadImage
                  alt={`${boat.name} - Úvodní fotografie`}
                  title={`${boat.name} - Úvodní fotografie`}
                  src={boat.mainImage} // use normal <img> attributes as props
                  effect="blur"
                  onClick={() => {
                    if (isIFrame)
                      navigate(
                        `${routesPath.IFRAME_BOAT}/${boat.url}/${boat.termId}`
                      );
                    else
                      navigate(`${routesPath.BOAT}/${boat.url}/${boat.termId}`);
                  }}
                />
              </div>
              <div className="content">
                <div
                  className="boat-name"
                  onClick={() => {
                    if (isIFrame)
                      navigate(
                        `${routesPath.IFRAME_BOAT}/${boat.url}/${boat.termId}`
                      );
                    else
                      navigate(`${routesPath.BOAT}/${boat.url}/${boat.termId}`);
                  }}
                >
                  {boat.name}
                </div>
                <span
                  className="boat-location"
                  onClick={() => setMapModalVisible(boat)}
                >
                  <CountryIcon
                    iconName={boat.locationIcon}
                    countryName={boat.locationIcon}
                    style={{ marginRight: 10 }}
                  />
                  {boat.locationName}
                  <span className="dot"></span>
                  {boat.charterName}
                </span>
                <div className="boat-tags">
                  <Tag title={`Lůžka: ${boat.berths}`} icon="icon-avatar" />
                  <Tag title={`Kajuty: ${boat.cabins}`} icon="icon-cabin" />
                  <Tag
                    title={`Délka: ${boat.length}`}
                    icon="icon-dimensions-–-length"
                  />
                  <Tag
                    title={`Šířka: ${boat.beam}`}
                    icon="icon-dimensions-–-width"
                  />
                  <Tag
                    title={`Rok výroby: ${boat.buildYear}`}
                    icon="icon-built"
                  />
                </div>
                <div className="date">
                  <i className="icon-calendar"></i> {boat.date}
                </div>
                <div className="footer">
                  <div className="query">
                    {boat.termState !== TermState.AVAILABLE && "Na dotaz"}
                  </div>
                  <div className="prices">
                    {boat.isDiscount && (
                      <>
                        <span className="discount">
                          {boat.discountPercentage}
                        </span>
                        <span className="price-before">{boat.priceBefore}</span>
                      </>
                    )}
                    <span className="price">{boat.price}</span>
                  </div>
                  <Button
                    text="Více info"
                    title="Více info"
                    size="medium"
                    customClass="more-info-button"
                    onClick={() => {
                      if (isIFrame)
                        navigate(
                          `${routesPath.IFRAME_BOAT}/${boat.url}/${boat.termId}`
                        );
                      else
                        navigate(
                          `${routesPath.BOAT}/${boat.url}/${boat.termId}`
                        );
                    }}
                  />
                </div>
              </div>
            </div>
          );
        })}
      </div>
      {mapModalVisible && (
        <Modal
          visible={!!mapModalVisible}
          onClose={() => setMapModalVisible(null)}
        >
          <Map
            markers={[
              {
                lat: Number(mapModalVisible.lat),
                lng: Number(mapModalVisible.lon),
                port: {
                  name: mapModalVisible.locationName,
                  icon: mapModalVisible.locationIcon,
                  location: mapModalVisible.locationName,
                },
              },
            ]}
            center={{
              lat: Number(mapModalVisible.lat),
              lng: Number(mapModalVisible.lon),
            }}
            zoom={10}
            height={"90vh"}
            selectDefaultMarker
          />
        </Modal>
      )}
    </>
  );
};
