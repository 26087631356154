import "./countryIcon.styles.scss";

interface Props {
  iconName: string;
  countryName: string;
  style?: any;
}

export const CountryIcon = ({ iconName, countryName, style }: Props) => {
  return (
    <img
      className="country-icon"
      src={`/static/img/Countries/${iconName}`}
      alt={`Ikona země ${countryName}`}
      title={`Ikona země ${countryName}`}
      style={style}
    />
  );
};
