import { Helmet } from "react-helmet";
import { Preparation } from "../../components/Preparation/Preparation";
import { Breadcrumb } from "../../components/Breadcrumb/Breadcrumb";
import { routesPath } from "../../routes/routes";

export const CaptainCoursesPage = () => {
  return (
    <>
      <Helmet>
        <title>YachtHub | Kapitánské kurzy</title>
      </Helmet>

      <div className="page">
        <Breadcrumb
          items={[
            { name: "Domů", path: routesPath.HOME },
            {
              name: "Kapitánské kurzy",
              path: routesPath.CAPTAIN_COURSES,
              isCurrent: true,
            },
          ]}
        />

        <h1>Kapitánské kurzy</h1>
        <Preparation />
      </div>
    </>
  );
};
