import {
  MultiSelectItem,
  MultiSelectItemType,
} from "../../../types/inputs/MultiSelect.types";
import { Button } from "../../Buttons/Button";
import { MultiSelectResultItem } from "./MultiSelectResultItem";

interface Props {
  isLoading: boolean;
  multiSelectItems: MultiSelectItem[] | null;
  selectedMultiSelectItems: MultiSelectItem[] | null;
  disableTypeName?: boolean;
  width?: number;
  query?: string;
  onSelect: (id: number, type: MultiSelectItemType) => void;
  onRemove: (id: number, type: MultiSelectItemType) => void;
  onRemoveAll: () => void;
}

export const MultiSelectResults = ({
  isLoading,
  multiSelectItems,
  selectedMultiSelectItems,
  disableTypeName,
  width,
  query,
  onSelect,
  onRemove,
  onRemoveAll,
}: Props) => {
  const multiSelectItemsWithoutSelected = multiSelectItems?.filter(
    (x) => !selectedMultiSelectItems?.some((y) => y.id === x.id)
  );

  return (
    <div className="results">
      {isLoading ? (
        <div className="text-result">Čekejte prosím...</div>
      ) : !multiSelectItemsWithoutSelected ||
        multiSelectItemsWithoutSelected.length === 0 ? (
        <div className="text-result">Nebyly nalezeny žádné výsledky.</div>
      ) : (
        <>
          {multiSelectItemsWithoutSelected.map(
            (multiSelectItem: MultiSelectItem, i) => {
              return (
                <MultiSelectResultItem
                  key={i}
                  multiSelectItem={multiSelectItem}
                  onSelect={onSelect}
                  disableTypeName={disableTypeName}
                  query={query}
                />
              );
            }
          )}
        </>
      )}

      {selectedMultiSelectItems && selectedMultiSelectItems.length > 0 && (
        <div>
          <hr />
          <div className="results-selected-topBar">
            <div className="headline">Tvůj výběr</div>
            <Button
              title="Smazat vše"
              icon="x_medium"
              text="Smazat vše"
              onClick={onRemoveAll}
            />
          </div>
          {selectedMultiSelectItems.map(
            (multiSelectItem: MultiSelectItem, i) => {
              return (
                <MultiSelectResultItem
                  key={i}
                  multiSelectItem={multiSelectItem}
                  onSelect={onSelect}
                  onRemove={onRemove}
                  disableTypeName={disableTypeName}
                  showRemoveButton
                />
              );
            }
          )}
        </div>
      )}
    </div>
  );
};
