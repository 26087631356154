import { ContactPage } from "./Pages/Contact/ContactPage";
import { HomePage } from "./Pages/Home/HomePage";
import { Routes, Route, useLocation } from "react-router-dom";
import { Layout } from "./layout/layout";
import { routesPath } from "./routes/routes";
import { SearchPage } from "./Pages/Search/SearchPage";
import { CaptainCoursesPage } from "./Pages/CaptainCourses/CaptainCoursesPage";
import { CruisesPage } from "./Pages/Cruises/CruisesPage";
import { DestinationsPage } from "./Pages/Destinations/DestinationsPage";
import { AboutPage } from "./Pages/About/AbouPage";
import { BoatPage } from "./Pages/Boat/BoatPage";
import { ReservationPage } from "./Pages/Reservation/ReservationPage";
import { useLayoutEffect } from "react";
import { InsurancePage } from "./Pages/Insurance/InsurancePage";
import { IFrameHomePageFilter } from "./Pages/IFrame/IFrameHomePageFilter";
import { IFrameConfig } from "./config/iframe";
import { IFrameSearchPage } from "./Pages/IFrame/IFrameSearchPage";

export const App = () => {
  const pathArr = window.location.pathname.split("/");
  const isIframe = pathArr.length > 1 && pathArr[1] === IFrameConfig.URL;

  const Wrapper = ({ children }: any) => {
    const location = useLocation();

    useLayoutEffect(() => {
      window.scrollTo({ top: 0, left: 0 });
    }, [location.pathname]);

    return children;
  };

  return (
    <div className="App">
      <Layout isIFrame={isIframe}>
        <Wrapper>
          <Routes>
            <Route
              key={routesPath.HOME}
              path={routesPath.HOME}
              element={<HomePage />}
            />
            <Route
              key={routesPath.SEARCH}
              path={routesPath.SEARCH}
              element={<SearchPage />}
            />
            <Route
              key={routesPath.BOAT}
              path={`${routesPath.BOAT}/:boatUrl/:termId?`}
              element={<BoatPage />}
            />
            <Route
              key={routesPath.RESERVATION}
              path={`${routesPath.RESERVATION}/:boatUrl/:termId?`}
              element={<ReservationPage />}
            />
            <Route
              key={routesPath.CAPTAIN_COURSES}
              path={routesPath.CAPTAIN_COURSES}
              element={<CaptainCoursesPage />}
            />
            <Route
              key={routesPath.CRUISES}
              path={routesPath.CRUISES}
              element={<CruisesPage />}
            />
            <Route
              key={routesPath.DESTINATIONS}
              path={routesPath.DESTINATIONS}
              element={<DestinationsPage />}
            />
            <Route
              key={routesPath.INSURANCE}
              path={routesPath.INSURANCE}
              element={<InsurancePage />}
            />
            <Route
              key={routesPath.ABOUT}
              path={routesPath.ABOUT}
              element={<AboutPage />}
            />
            <Route
              key={routesPath.CONTACT}
              path={routesPath.CONTACT}
              element={<ContactPage />}
            />
            <Route
              key={routesPath.IFRAME_HOME}
              path={routesPath.IFRAME_HOME}
              element={<IFrameHomePageFilter />}
            />
            <Route
              key={routesPath.IFRAME_SEARCH}
              path={routesPath.IFRAME_SEARCH}
              element={<IFrameSearchPage />}
            />
            <Route
              key={routesPath.IFRAME_BOAT}
              path={`${routesPath.IFRAME_BOAT}/:boatUrl/:termId?`}
              element={<BoatPage />}
            />
            <Route
              key={routesPath.IFRAME_RESERVATION}
              path={`${routesPath.IFRAME_RESERVATION}/:boatUrl/:termId?`}
              element={<ReservationPage />}
            />
          </Routes>
        </Wrapper>
      </Layout>
    </div>
  );
};
