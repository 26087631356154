import { useNavigate } from "react-router-dom";
import { Button } from "../../components/Buttons/Button";
import { routesPath } from "../../routes/routes";
import { ButtonLink } from "../../components/Buttons/ButtonLink";

export const NoResults = () => {
  const navigate = useNavigate();
  return (
    <div className="no-results">
      <div className="title">Bohužel jsme nenašli žádnou loď.</div>
      <i className="icon-dash"></i>
      <p>Rádi ti osobně zjistíme dostupnost. V případě zájmu nás kontaktuj.</p>
      <div className="buttons">
        <ButtonLink
          title="+420 777 644 491"
          text="+420 777 644 491"
          type="yellow"
          size="large"
          href="tel:+420 777 644 491"
        />
        <Button
          title="Napiš nám"
          text="Napiš nám"
          type="gold"
          size="large"
          onClick={() => navigate(routesPath.CONTACT)}
        />
      </div>
    </div>
  );
};
