import { Helmet } from "react-helmet";
import { Preparation } from "../../components/Preparation/Preparation";
import { Breadcrumb } from "../../components/Breadcrumb/Breadcrumb";
import { routesPath } from "../../routes/routes";

export const InsurancePage = () => {
  return (
    <>
      <Helmet>
        <title>YachtHub | Pojištění</title>
      </Helmet>

      <div className="page">
        <Breadcrumb
          items={[
            { name: "Domů", path: routesPath.HOME },
            {
              name: "Pojištění",
              path: routesPath.INSURANCE,
              isCurrent: true,
            },
          ]}
        />

        <h1>Pojištění</h1>
        <Preparation />
      </div>
    </>
  );
};
