import { useCallback, useState } from "react";
import "./homePage.styles.scss";
import { DatePicker } from "../../components/inputs/DatePicker/DatePicker";
import {
  defaultValues,
  HomepageFilterForm,
} from "../../types/filter/homepageFilter.types";
import {
  MultiSelectItem,
  MultiSelectItemType,
} from "../../types/inputs/MultiSelect.types";
import { Moment } from "moment";
import { useLocation, useNavigate } from "react-router-dom";
import { routesPath } from "../../routes/routes";
import { MultiSelect2 } from "../../components/inputs/MultiSelect/MultiSelect2";

interface Focus {
  destination: boolean;
  date: boolean;
}

export const WelcomeContainer = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const isIFrame = location.pathname === "/iframe";

  const [filterData, setFilterData] =
    useState<HomepageFilterForm>(defaultValues);

  const [focus, setFocus] = useState<Focus>({
    destination: false,
    date: false,
  });

  const handleFocusChange = useCallback(
    (isFocused: boolean, type: "destination" | "date") => {
      if (type === "destination") {
        setFocus({
          ...focus,
          destination: isFocused,
          date: isFocused ? false : focus.date,
        });
      }

      if (type === "date") {
        setFocus({
          ...focus,
          date: isFocused,
          destination: isFocused ? false : focus.destination,
        });
      }
    },
    [focus]
  );

  const handlePrimaryMultiSelectChange = useCallback(
    (selectedItems: MultiSelectItem[]) => {
      const countries = selectedItems.filter(
        (x) => x.type === MultiSelectItemType.COUNTRY
      );
      const regions = selectedItems.filter(
        (x) => x.type === MultiSelectItemType.REGION
      );
      const bases = selectedItems.filter(
        (x) => x.type === MultiSelectItemType.BASE
      );
      const charters = selectedItems.filter(
        (x) => x.type === MultiSelectItemType.CHARTER
      );

      setFilterData((prevData) => ({
        ...prevData,
        countries: countries.map((x) => x.id),
        regions: regions.map((x) => x.id),
        bases: bases.map((x) => x.id),
        charters: charters.map((x) => x.id),
        multiSelectItemsCountries: countries,
        multiSelectItemsRegions: regions,
        multiSelectItemsBases: bases,
        multiSelectItemsCharters: charters,
      }));
    },
    []
  );

  const handleDateChange = useCallback((dateFrom?: Moment, dateTo?: Moment) => {
    setFilterData((prevData) => ({
      ...prevData,
      dateFrom: dateFrom || null,
      dateTo: dateTo || null,
    }));
  }, []);

  const handleSubmit = useCallback(() => {
    const _filterData = JSON.stringify({
      multiSelectItemsCountries: filterData.multiSelectItemsCountries,
      multiSelectItemsRegions: filterData.multiSelectItemsRegions,
      multiSelectItemsBases: filterData.multiSelectItemsBases,
      multiSelectItemsCharters: filterData.multiSelectItemsCharters,
      countries: filterData.countries,
      regions: filterData.regions,
      bases: filterData.bases,
      charters: filterData.charters,
      dateFrom: filterData.dateFrom,
      dateTo: filterData.dateTo,
    });

    // @ts-ignore
    if (isIFrame) {
      window.parent.postMessage(_filterData, "*");
    } else
      navigate(routesPath.SEARCH, {
        state: {
          filterData: _filterData,
        },
      });
  }, [filterData]);

  return (
    <div className={`welcome-container${isIFrame ? " iframe" : ""}`}>
      <div className="welcome-background">
        <div className="welcome">
          {!isIFrame && (
            <>
              <h1>NOVÝ VÍTR DO SVĚTA JACHTINGU</h1>
              <h2>
                Sháníš loď pro svou dovolenou? Od nás bleskově dostaneš nabídku
                na míru.
              </h2>
            </>
          )}
          <div className="search-container">
            <div className="search-bar">
              <div
                className={`search-item destination${
                  focus.destination ? " active" : ""
                }`}
              >
                <MultiSelect2
                  name={"primary-homepage-multiselect"}
                  icon="icon-anchor"
                  title="Destinace"
                  placeholder="Kam popluješ?"
                  type={null}
                  defaultValues={[
                    ...filterData.multiSelectItemsCountries,
                    ...filterData.multiSelectItemsBases,
                    ...filterData.multiSelectItemsRegions,
                    ...filterData.multiSelectItemsCharters,
                  ]}
                  selectedEntities={[
                    ...filterData.multiSelectItemsCountries,
                    ...filterData.multiSelectItemsBases,
                    ...filterData.multiSelectItemsRegions,
                    ...filterData.multiSelectItemsCharters,
                  ]}
                  onChange={(selectedValues: MultiSelectItem[]) =>
                    handlePrimaryMultiSelectChange(selectedValues)
                  }
                  onInputFocusedChange={(isFocused: boolean) => {
                    handleFocusChange(isFocused, "destination");
                  }}
                  isMobile={window.innerWidth <= 585}
                />
              </div>
              <hr className="divider" />
              <div className={`search-item date${focus.date ? " active" : ""}`}>
                <DatePicker
                  name={"primary-homepage-datepicker"}
                  defaultValues={{
                    dateFrom: filterData.dateFrom,
                    dateTo: filterData.dateTo,
                  }}
                  onChange={handleDateChange}
                  onInputFocusedChange={(isFocused: boolean) =>
                    handleFocusChange(isFocused, "date")
                  }
                  defaultActive
                  calendarDisplayLeft
                  icon="icon-calendar"
                  isMobile={window.innerWidth <= 980}
                />
              </div>
              <button
                title="Hledat"
                className="search-button"
                onClick={handleSubmit}
              >
                <div className="search-button-text">HLEDAT</div>
                <i className="icon-search"></i>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
