import {
  GoogleMap,
  InfoWindow,
  Marker,
  useJsApiLoader,
} from "@react-google-maps/api";
import { useCallback, useState } from "react";
import "./map.styles.scss";
import { MarkerType } from "./Map.types";
import { CountryIcon } from "../CountryIcon/CountryIcon";
import React from "react";

interface Props {
  markers: MarkerType[];
  center: { lat: number; lng: number };
  zoom: number;
  height?: string;
  selectDefaultMarker?: boolean;
}

export const Map = ({
  markers,
  center,
  zoom,
  height,
  selectDefaultMarker,
}: Props) => {
  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: "AIzaSyAsnuvA7fkwelA7k0_JSSFQlgL3mq-XugA",
  });

  const [selectedMarker, setSelectedMarker] = useState<
    MarkerType | undefined | null
  >(selectDefaultMarker ? markers[0] : null);

  const containerStyle = {
    width: "100%",
    height: height || "400px",
    borderRadius: "15px",
  };

  const onClickMarker = useCallback(
    (markerIndex: number) => {
      setSelectedMarker(markers[markerIndex]);
    },
    [markers]
  );

  return isLoaded ? (
    <div className="map-container">
      <GoogleMap mapContainerStyle={containerStyle} center={center} zoom={zoom}>
        {markers.map((marker: MarkerType, index: number) => {
          return (
            <React.Fragment key={index}>
              <Marker
                icon={{
                  url: "/static/img/Ico/Basic/Tag.svg",
                  scaledSize: new google.maps.Size(35, 40),
                }}
                position={{
                  lat: marker.lat,
                  lng: marker.lng,
                }}
                onClick={() => onClickMarker(index)}
              />
            </React.Fragment>
          );
        })}
        {selectedMarker && (
          <InfoWindow
            position={{
              lat: selectedMarker.lat,
              lng: selectedMarker.lng,
            }}
            options={{ pixelOffset: new window.google.maps.Size(0, -40) }}
            onCloseClick={() => setSelectedMarker(null)}
          >
            <span
              className="boat-location"
              style={{ marginTop: 15, marginBottom: 15 }}
            >
              <CountryIcon
                iconName={selectedMarker.port.icon}
                countryName={selectedMarker.port.location}
                style={{ marginRight: 10 }}
              />
              {selectedMarker.port.name}
            </span>
          </InfoWindow>
        )}
      </GoogleMap>
    </div>
  ) : (
    <></>
  );
};
