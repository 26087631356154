import { useCallback, useEffect, useRef, useState } from "react";
import "./datePicker.styles.scss";
import { useOutsideClick } from "../../../hooks/useOutsideClick";
import { Calendar, DateSelection } from "./Calendar";
import { Moment } from "moment";
import { FulscreenMobileContainer } from "../../../layout/fullscreen/fulscreenMobileContainer";

interface Props {
  name: string;
  defaultValues: { dateFrom: Moment | null; dateTo: Moment | null };
  defaultActive?: boolean;
  calendarDisplayLeft?: boolean;
  icon: string;
  isMobile: boolean;
  onChange: (dateFrom?: Moment, dateTo?: Moment) => void;
  onInputFocusedChange?: (isFocused: boolean) => void;
}

export const DatePicker = ({
  name,
  defaultValues,
  defaultActive,
  calendarDisplayLeft,
  icon,
  isMobile,
  onChange,
  onInputFocusedChange,
}: Props) => {
  // #region References
  const inputRef = useRef<HTMLInputElement>(null);
  const [inputValue, setInputValue] = useState(
    !defaultValues.dateFrom || !defaultValues.dateTo
      ? ""
      : `${defaultValues.dateFrom!.format(
          "DD.MM.YYYY"
        )} - ${defaultValues.dateTo!.format("DD.MM.YYYY")}`
  );
  const [selection, setSelection] = useState<DateSelection>({
    from: defaultValues.dateFrom || null,
    to: defaultValues.dateTo || null,
  });
  // #endregion

  // #region States
  // Aktivní = možnost zadávat, jinak se input nezobrazuje
  const [isActive, setActive] = useState(defaultActive);
  // Input pro zadávání focus
  const [inputFocused, setInputFocused] = useState(false);
  // #endregion

  // #region Handlers
  // Kliknutí na komponentu
  const handleClicked = useCallback((e: any) => {
    if (e.target.nodeName === "BUTTON") return;
    setInputFocused(true);
    setActive(true);
    setInputFocus();
  }, []);

  // Změna hodnoty inputu
  const handleInputValueChanged = useCallback((value: string) => {}, []);

  // Kliknutí mimo komponentu
  const handleOutsideClicked = useCallback(() => {
    setInputFocused(false);
  }, []);

  const datePickerRef = useOutsideClick(handleOutsideClicked);

  // Nastaví focus na input
  const setInputFocus = useCallback(() => {
    const timeoutId = setTimeout(() => {
      if (!inputRef || !inputRef.current) return;
      inputRef.current.focus();
    }, 250);
    return () => {
      clearTimeout(timeoutId);
    };
  }, [inputRef]);

  const handleSelectDates = useCallback(
    (
      selection: DateSelection,
      closeCalendar: boolean,
      triggerOnChange: boolean
    ) => {
      setSelection(selection);
      if (triggerOnChange)
        onChange(selection.from || undefined, selection.to || undefined);

      if (!selection.from || !selection.to) {
        setInputValue("");
        if (closeCalendar) setInputFocused(false);
        return;
      }

      setInputValue(
        `${selection.from!.format("DD.MM.YYYY")} - ${selection.to!.format(
          "DD.MM.YYYY"
        )}`
      );

      if (closeCalendar) setInputFocused(false);
    },
    [onChange]
  );
  // #endregion

  useEffect(() => {
    if (
      selection.from === defaultValues.dateFrom &&
      selection.to === defaultValues.dateTo
    )
      return;
    handleSelectDates(
      { from: defaultValues.dateFrom, to: defaultValues.dateTo },
      false,
      false
    );
  }, [defaultValues]);

  // On focus change
  useEffect(() => {
    if (onInputFocusedChange) onInputFocusedChange(inputFocused);
  }, [inputFocused]);

  return (
    <div
      ref={datePickerRef}
      className={`datepicker${isMobile ? " datepicker-mobile" : ""}`}
      onClick={(e: any) =>
        inputFocused && isMobile ? undefined : handleClicked(e)
      }
    >
      {((isActive && !isMobile) || (isMobile && !inputFocused)) && (
        <div className="enter-zone">
          <i className={icon}></i>
          <div className="input">
            <label className={isActive ? "active" : "not-active"}>Termín</label>
            <input
              ref={inputRef}
              type="text"
              title={name}
              value={inputValue}
              placeholder="Kdy popluješ?"
              onChange={(e: React.FormEvent<HTMLInputElement>) =>
                handleInputValueChanged(e.currentTarget.value)
              }
              style={{ zIndex: 1500 }}
              onFocus={() => setInputFocused(true)}
            />
          </div>
        </div>
      )}

      {inputFocused && !isMobile && (
        <Calendar
          onSelect={handleSelectDates}
          defaultSelection={selection}
          calendarDisplayLeft={calendarDisplayLeft}
          isMobile={isMobile}
        />
      )}

      {inputFocused && isMobile && (
        <FulscreenMobileContainer
          title="Termín"
          onClose={() => {
            setInputFocused(false);
          }}
          icon={icon}
        >
          <div className="mobile">
            <Calendar
              onSelect={handleSelectDates}
              defaultSelection={selection}
              calendarDisplayLeft={calendarDisplayLeft}
              isMobile={isMobile}
            />
          </div>
        </FulscreenMobileContainer>
      )}
    </div>
  );
};
