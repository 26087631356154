import { useCallback, useRef } from "react";
import "./textBox.styles.scss";

interface Props {
  name: string;
  label: string;
  placeholder: string;
  type: "text" | "number";
  value?: string;
  multiline?: boolean;
  isError?: boolean;
  onChange: (value: string) => void;
}

export const TextBox = ({
  name,
  label,
  placeholder,
  type,
  value,
  multiline,
  isError,
  onChange,
}: Props) => {
  const inputRef = useRef<any>();

  const focusInput = useCallback(() => {
    inputRef.current.focus();
  }, [inputRef]);

  return (
    <div className={`text-box ${isError ? " error" : ""}`} onClick={focusInput}>
      <div className="enter-zone">
        <div className="input">
          <label className="active">{label}</label>
          {multiline ? (
            <textarea
              ref={inputRef}
              name={name}
              placeholder={placeholder}
              rows={5}
              value={value}
              onChange={(e) => onChange(e.target.value)}
            />
          ) : (
            <input
              ref={inputRef}
              type={type}
              name={name}
              placeholder={placeholder}
              value={value}
              onChange={(e) => onChange(e.target.value)}
            />
          )}
        </div>
      </div>
    </div>
  );
};
