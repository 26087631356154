import "./iframe.styles.scss";
import { SearchPage } from "../Search/SearchPage";
import { useLocation, useSearchParams } from "react-router-dom";
import { Loader } from "../../components/Loader/Loader";
import axios from "../../api/axios";
import { apiUrl } from "../../api/apiUrl";
import { useEffect, useState } from "react";
import { MultiSelectItemType } from "../../types/inputs/MultiSelect.types";
import { SessionStorageKey } from "../../config/sessionStorage";
import moment from "moment";

export const IFrameSearchPage = () => {
  const { state } = useLocation();
  const [searchParams] = useSearchParams();
  const [loading, setLoading] = useState(true);
  const allParams = Array.from(searchParams.entries());

  const countries = allParams
    .filter((x) => x[0] === "countries")
    .map((x) => {
      return x;
    });
  const regions = allParams
    .filter((x) => x[0] === "regions")
    .map((x) => {
      return x;
    });
  const bases = allParams
    .filter((x) => x[0] === "bases")
    .map((x) => {
      return x;
    });
  const charters = allParams
    .filter((x) => x[0] === "charters")
    .map((x) => {
      return x;
    });
  const dateFrom = allParams
    .filter((x) => x[0] === "dateFrom")
    .map((x) => {
      return x[1];
    });
  const dateTo = allParams
    .filter((x) => x[0] === "dateTo")
    .map((x) => {
      return x[1];
    });

  const fetchMultiSelectItems = async () => {
    if (
      countries.length === 0 &&
      regions.length === 0 &&
      bases.length === 0 &&
      charters.length === 0
    ) {
      setFiltersNoMulti();
      setLoading(false);
      return;
    }
    const result = await axios.apiPost({
      url: `${apiUrl.MULTI_SELECT_ITEM_LIST_SELECTED}`,
      data: [
        ...countries.map((item) => {
          return { id: item[1], type: MultiSelectItemType.COUNTRY };
        }),
        ...regions.map((item) => {
          return { id: item[1], type: MultiSelectItemType.REGION };
        }),
        ...bases.map((item) => {
          return { id: item[1], type: MultiSelectItemType.BASE };
        }),
        ...charters.map((item) => {
          return { id: item[1], type: MultiSelectItemType.CHARTER };
        }),
      ],
    });

    if (result?.status === 200) {
      sessionStorage.setItem(
        SessionStorageKey.FILTER,
        JSON.stringify({
          countries: result.data
            .filter((x: any) => x.type === MultiSelectItemType.COUNTRY)
            .map((x: any) => x.id),
          multiSelectItemsCountries: result.data.filter(
            (x: any) => x.type === MultiSelectItemType.COUNTRY
          ),
          regions: result.data
            .filter((x: any) => x.type === MultiSelectItemType.REGION)
            .map((x: any) => x.id),
          multiSelectItemsRegions: result.data.filter(
            (x: any) => x.type === MultiSelectItemType.REGION
          ),
          bases: result.data
            .filter((x: any) => x.type === MultiSelectItemType.BASE)
            .map((x: any) => x.id),
          multiSelectItemsBases: result.data.filter(
            (x: any) => x.type === MultiSelectItemType.BASE
          ),
          charters: result.data
            .filter((x: any) => x.type === MultiSelectItemType.CHARTER)
            .map((x: any) => x.id),
          multiSelectItemsCharters: result.data.filter(
            (x: any) => x.type === MultiSelectItemType.CHARTER
          ),
          dateFrom:
            dateFrom.length > 0
              ? moment(dateFrom[0]).add(1, "days").format("YYYY-MM-DD")
              : null,
          dateTo:
            dateTo.length > 0
              ? moment(dateTo[0]).add(1, "days").format("YYYY-MM-DD")
              : null,
        })
      );
      setLoading(false);
    }
  };

  const setFiltersNoMulti = () => {
    const sessionFilter = sessionStorage.getItem(SessionStorageKey.FILTER);
    console.log("setFiltersNoMulti sessionFilter", sessionFilter);
    console.log("state", state);
    if (sessionFilter && state && state.isFromBoatDetail) {
      sessionStorage.setItem(
        SessionStorageKey.FILTER,
        JSON.stringify({
          ...JSON.parse(sessionFilter),
        })
      );
    } else {
      sessionStorage.setItem(
        SessionStorageKey.FILTER,
        JSON.stringify({
          dateFrom:
            dateFrom.length > 0
              ? moment(dateFrom[0]).add(1, "days").format("YYYY-MM-DD")
              : null,
          dateTo:
            dateTo.length > 0
              ? moment(dateTo[0]).add(1, "days").format("YYYY-MM-DD")
              : null,
        })
      );
    }
  };

  useEffect(() => {
    // Pokud nejdeme z detailu lodi nebo jdeme, ale parametr je nastavený na false
    // fetch chceme volat jen při inicializaci
    if (!state || !state.isFromBoatDetail) fetchMultiSelectItems();
    else {
      console.log("else");
      setFiltersNoMulti();
      setLoading(false);
    }
  }, []);

  if (loading) return <Loader />;
  // if (!allParams || allParams.length === 0) return <Loader />;
  return (
    <>
      <SearchPage
        iFrameSetting={{
          showBreadcrumb: false,
        }}
      />
    </>
  );
};
