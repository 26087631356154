import moment, { Moment } from "moment";
import { BoatDetail } from "../../types/entities/Boat.types";
import { TermWithMessage } from "../../types/entities/Term.types";
import { Button } from "../Buttons/Button";
import { DatePicker } from "../inputs/DatePicker/DatePicker";
import { Loader } from "../Loader/Loader";
import { routesPath } from "../../routes/routes";
import { useNavigate } from "react-router-dom";
import "./reservationBox.styles.scss";

interface Props {
  termLoading: boolean;
  termWithMessage: TermWithMessage | null;
  boat: BoatDetail | null;
  isIFrame: boolean;
  handleDateChange: (dateFrom?: Moment, dateTo?: Moment) => void;
}

export const ReservationBox = ({
  termLoading,
  termWithMessage,
  boat,
  isIFrame,
  handleDateChange,
}: Props) => {
  const navigate = useNavigate();

  return termLoading || !termWithMessage || !boat ? (
    <div className="reservation-box-container">
      <Loader text="Ověřujeme cenu" customClass="reservation-box" />
    </div>
  ) : (
    <div className="reservation-box-container">
      <div className="reservation-box">
        <h2>REZERVACE</h2>
        {termWithMessage.term && (
          <>
            {termWithMessage.term.isDiscount && (
              <div className="price-row">
                <div className="label">Původní cena</div>
                <div className="value">
                  <span className="discount">
                    {termWithMessage.term.discountPercentage}
                  </span>
                  <span className="price-before">
                    {termWithMessage.term.priceBefore}
                  </span>
                </div>
              </div>
            )}
            <div className="price-row">
              <div className="label">Tvoje cena</div>
              <div className="value">
                <span className="price">{termWithMessage.term.price}</span>
              </div>
            </div>
            {boat.deposit && (
              <div className="price-row">
                <div className="label">Kauce</div>
                <div className="value">{boat.deposit}</div>
              </div>
            )}
          </>
        )}
        <DatePicker
          name={"primary-datepicker"}
          defaultValues={{
            dateFrom: termWithMessage.term
              ? moment(termWithMessage.term.from)
              : null,
            dateTo: termWithMessage.term
              ? moment(termWithMessage.term.to)
              : null,
          }}
          onChange={handleDateChange}
          icon="icon-calendar"
          isMobile={window.innerWidth <= 980}
          calendarDisplayLeft
          defaultActive
        />
        <Button
          title="Nezávazná rezervace"
          text="Nezávazná rezervace"
          size="full-width"
          type="gold"
          customClass="btn-reservation"
          onClick={() => {
            if (!isIFrame)
              navigate(
                `${routesPath.RESERVATION}/${boat.url}/${termWithMessage.term.id}`
              );
            else
              navigate(
                `${routesPath.IFRAME_RESERVATION}/${boat.url}/${termWithMessage.term.id}`
              );
          }}
          disabled={!termWithMessage.isAvaliable}
        />
        <div className="term-message">
          {termWithMessage.isAvaliable ? (
            <i className="icon-check_medium available"></i>
          ) : (
            <i className="icon-error not-available"></i>
          )}
          {termWithMessage.message}
        </div>
      </div>
    </div>
  );
};
