export interface Country {
  id: number;
  name: string;
  iconName: string;
}

export const homepageCountries: Country[] = [
  {
    id: 101,
    name: "Chorvatsko",
    iconName: "croatia.svg",
  },
  {
    id: 24,
    name: "Řecko",
    iconName: "greece.svg",
  },
  {
    id: 218,
    name: "Itálie",
    iconName: "italy.svg",
  },
  {
    id: 207,
    name: "Španělsko",
    iconName: "spain.svg",
  },
  {
    id: 186,
    name: "Švédsko",
    iconName: "sweden.svg",
  },
  {
    id: 124,
    name: "Spojené Království",
    iconName: "united-kingdom.svg",
  },
  {
    id: 59,
    name: "Francie",
    iconName: "france.svg",
  },
  {
    id: 151,
    name: "Turecko",
    iconName: "turkey.svg",
  },
];

export const footerCountries: Country[] = [
  {
    id: 101,
    name: "Chorvatsko",
    iconName: "croatia.svg",
  },
  {
    id: 24,
    name: "Řecko",
    iconName: "greece.svg",
  },
  {
    id: 218,
    name: "Itálie",
    iconName: "italy.svg",
  },
  {
    id: 207,
    name: "Španělsko",
    iconName: "spain.svg",
  },
  {
    id: 186,
    name: "Švédsko",
    iconName: "sweden.svg",
  },
  {
    id: 124,
    name: "Spojené Království",
    iconName: "united-kingdom.svg",
  },
  {
    id: 59,
    name: "Francie",
    iconName: "france.svg",
  },
  {
    id: 151,
    name: "Turecko",
    iconName: "turkey.svg",
  },
];
