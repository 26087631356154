import { SearchPaginationItem } from "./SearchPaginationItem";

interface Props {
  currentPage: number;
  lastPage: number;
  onChange: (page: number) => void;
}

export const SearchPagination = ({
  lastPage,
  currentPage,
  onChange,
}: Props) => {
  const range = 3;

  if (lastPage === -1) return <></>;

  return (
    <div className="pagination">
      {Array.from(Array(lastPage).keys())
        .map((i) => i + 1)
        .map((page: number, key: number) => {
          // Číslo 1 => vždy chceme vidět - pokud jsme od něj daleko, zobrazíme s volnkou
          if (key < currentPage - range && page === 1)
            return (
              <>
                <SearchPaginationItem
                  key={key}
                  page={page}
                  isActive={currentPage + 1 === 1}
                  onClick={() => onChange(page - 1)}
                />
                <i className="icon-dash wave"></i>
              </>
            );
          //  Zobrazuejeme jen stránky v rozsahu
          if (key < currentPage - range) return;
          // Pokud jsme těsně před koncem a zároveň mimo rozsah, zobrazíme vlnku
          if (key > currentPage + range && page === lastPage - 1)
            return <i key={key} className="icon-dash wave"></i>;
          //  Zobrazuejeme jen stránky v rozsahu
          if (key > currentPage + range) return;

          return (
            <SearchPaginationItem
              key={key}
              page={page}
              isActive={currentPage + 1 === page}
              onClick={() => onChange(page - 1)}
            />
          );
        })}

      <SearchPaginationItem
        key={lastPage + 1}
        page={lastPage + 1}
        isActive={currentPage + 1 === lastPage + 1}
        onClick={() => onChange(lastPage)}
      />
      <div
        className={`pagination-arrow left${
          currentPage === 0 ? " not-active" : ""
        }`}
        onClick={() =>
          currentPage === 0 ? undefined : onChange(currentPage - 1)
        }
      >
        <i className="icon-arrow_l"></i>
      </div>
      <div
        className={`pagination-arrow${
          currentPage === lastPage ? " not-active" : ""
        }`}
        onClick={() =>
          currentPage === lastPage ? undefined : onChange(currentPage + 1)
        }
      >
        <i className="icon-arrow_l"></i>
      </div>
    </div>
  );
};
