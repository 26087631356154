import moment, { Moment } from "moment";
import { useEffect, useMemo, useState } from "react";
import { DateSelection } from "./Calendar";

interface Props {
  month: Moment;
  selection: DateSelection;
  onDaySelect: (day: Moment) => void;
}

export const MonthContainer = ({ month, selection, onDaySelect }: Props) => {
  const [days, setDays] = useState<(Moment | null)[]>([]);

  const today = useMemo(() => {
    return moment();
  }, []);

  //#region handlers

  //#endregion

  useEffect(() => {
    const startOfMonth = moment(month).startOf(`month`);
    const endOfMonth = moment(month).endOf(`month`);

    const _days = [];
    for (let i = 0; i < startOfMonth.weekday(); i++) {
      _days.push(null);
    }

    for (let day = startOfMonth; day.isBefore(endOfMonth); day.add(1, "days")) {
      _days.push(moment(day));
    }

    setDays(_days);
  }, [month]);

  if (days.length === 0) return <></>;
  return (
    <div className="month-container">
      <div className="month-title">{moment(month).format(`MMMM YYYY`)}</div>
      <div className="month-table">
        <div className="day-header">
          <span>Po</span>
        </div>
        <div className="day-header">
          <span>Út</span>
        </div>
        <div className="day-header">
          <span>St</span>
        </div>
        <div className="day-header">
          <span>Čt</span>
        </div>
        <div className="day-header">
          <span>Pá</span>
        </div>
        <div className="day-header">
          <span>So</span>
        </div>
        <div className="day-header">
          <span>Ne</span>
        </div>
        {days.map((day, i) => {
          const isDisabled = day?.isBefore(today);
          const isSaturday = day?.day() === 6;
          const isSelected =
            selection.from?.isSame(day) || selection.to?.isSame(day);
          const isInSelection =
            day?.isSameOrAfter(selection.from) &&
            day.isSameOrBefore(selection.to);
          const isFirstSelection = day?.isSame(selection.from);
          const isLastSelection = day?.isSame(selection.to);

          return (
            <div
              key={i}
              className={`day-cell${isInSelection ? " in-selection" : ""}${
                isFirstSelection ? " first" : ""
              }${isLastSelection ? " last" : ""}`}
              onClick={() =>
                day && !isDisabled ? onDaySelect(day) : undefined
              }
            >
              <span
                className={`day ${isDisabled ? ` disabled` : ``}${
                  isSaturday && !isInSelection ? " saturday" : ""
                }${isSelected ? " selected" : ""}`}
              >
                {day?.format(`D`)}
              </span>
            </div>
          );
        })}
      </div>
    </div>
  );
};
