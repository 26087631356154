export interface MultiSelectItem {
  id: number;
  name: string;
  type: MultiSelectItemType;
  typeName: string;
  iconName: string | null;
  url?: string;
}

export enum MultiSelectItemType {
  COUNTRY = 0,
  REGION = 1,
  BASE = 2,
  CHARTER = 3,
  BOAT = 4,
  BOAT_CATEGORY = 5,
}

export enum MultiSelectItemTypeName {
  COUNTRY = "Země",
  REGION = "Region",
  BASE = "Přístav",
  CHARTER = "Společnost",
  BOAT = "Loď",
  BOAT_CATEGORY = "Typ lodě",
}
