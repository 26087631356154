import moment from "moment";

export const Grant = () => {
  return (
    <div className="grant">
      <div className="content">
        <div className="links">
          <nav>
            <ul>
              <li>©{moment().format("YYYY")} YachtHub s.r.o.</li>
              <li className="pardubice">
                <img
                  className="logo"
                  src="/static/img/Pics/pardubicky_kraj.svg"
                  title="Pardubický kraj"
                  alt="Pardubický kraj"
                />
                <span>Pardubický kraj</span>
              </li>
            </ul>
          </nav>
          <nav>
            <ul>
              <li>Cookies</li>
              <li>
                <a
                  href="/static/docs/YachtHub-obchodni-podminky-gdpr.pdf"
                  target="_blank"
                  title="Obchodní podmínky a ochrana osobních údajů"
                >
                  Obchodní podmínky a ochrana osobních údajů
                </a>
              </li>
            </ul>
          </nav>
        </div>
        <p>
          Projekt YachtHub.cz byl podpořen z prostředků Pardubického kraje z
          podprogramu Podpora nových podnikatelských záměrů a rozvoje regionální
          podnikatelské aktivity – Startovací vouchery.
        </p>
      </div>
    </div>
  );
};
