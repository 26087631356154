import { Helmet } from "react-helmet";
import "./searchPage.styles.scss";
import { Filter } from "./Filter";
import axios from "../../api/axios";
import { useCallback, useState } from "react";
import {
  SearchFilterResult,
  OrderBy,
  SearchFilterForm,
  defaultValues,
} from "../../types/filter/filter.types";
import { apiUrl } from "../../api/apiUrl";
import { SearchHeader } from "./SearchHeader";
import { FilterOrderBy } from "./FilterOrderBy";
import { SearchResults } from "./SearchResults";
import { SearchResultsLoader } from "./SearchResultsLoader";
import { SearchPagination } from "./SearchPagination";
import { useLocation } from "react-router-dom";
import { Breadcrumb } from "../../components/Breadcrumb/Breadcrumb";
import { routesPath } from "../../routes/routes";
import { Button } from "../../components/Buttons/Button";
import { CircleButton } from "../../components/Buttons/CircleButton";
import { FulscreenMobileContainer } from "../../layout/fullscreen/fulscreenMobileContainer";
import { NoResults } from "./NoResults";
import { ErrorSearch } from "./ErrorSearch";
import { SessionStorageKey } from "../../config/sessionStorage";

interface Props {
  iFrameSetting?: {
    showBreadcrumb: boolean;
  };
}

export const SearchPage = ({ iFrameSetting }: Props) => {
  const { state } = useLocation();

  // Default filter state
  const sessionFilter = sessionStorage.getItem(SessionStorageKey.FILTER);
  const stateObj =
    state && !state.isFromBoatDetail
      ? JSON.parse(state.filterData)
      : sessionFilter
      ? JSON.parse(sessionFilter)
      : null;

  // States
  const [loading, setLoading] = useState(true);
  const [boatSearchResultError, setBoatSearchResultError] = useState(false);
  const [boatSearchResult, setBoatSearchResult] =
    useState<SearchFilterResult | null>(null);
  const [lastFilterData, setLastFilterData] = useState<SearchFilterForm | null>(
    null
  );
  const [orderBy, setOrderBy] = useState<OrderBy | null>(
    stateObj ? stateObj.orderBy : null
  );
  // Mobile states
  const [isMobileFilterOpen, setMobileFilterOpen] = useState(false);
  const [isMobileFilterOrderOpen, setMobileFilterOrder] = useState(false);

  const submitSearch = useCallback(
    async (filterData: SearchFilterForm, _orderBy?: OrderBy) => {
      setLoading(true);
      setLastFilterData({ ...filterData });
      setBoatSearchResultError(false);
      if (_orderBy !== undefined) setOrderBy(_orderBy);

      const postData = {
        ...filterData,
        orderBy:
          _orderBy !== undefined
            ? _orderBy
            : orderBy !== null
            ? orderBy
            : defaultValues.orderBy,
        dateFrom: filterData.dateFrom?.format("yyyy-MM-DD"),
        dateTo: filterData.dateTo?.format("yyyy-MM-DD"),
      };

      const result = await axios.apiPost({
        url: `${apiUrl.SEARCH}`,
        data: postData,
      });

      sessionStorage.setItem(
        SessionStorageKey.FILTER,
        JSON.stringify(postData)
      );
      if (result?.status === 200) setBoatSearchResult(result.data);
      else setBoatSearchResultError(true);

      setLoading(false);
    },
    [lastFilterData, orderBy]
  );

  return (
    <>
      <Helmet>
        <title>YachtHub | Najdi si loď</title>
      </Helmet>
      <div className="page">
        {!iFrameSetting ||
          (iFrameSetting.showBreadcrumb && (
            <Breadcrumb
              items={[
                { name: "Domů", path: routesPath.HOME },
                {
                  name: "Pronájem lodí",
                  path: routesPath.SEARCH,
                  isCurrent: true,
                },
              ]}
            />
          ))}

        <div className="search-page">
          <div className="filter">
            <Filter
              defaultFilterData={
                stateObj ? { ...defaultValues, ...stateObj } : defaultValues
              }
              onFilterDataChanged={submitSearch}
              orderBy={orderBy}
            />
          </div>
          <div className="content">
            <SearchHeader
              boatSearchResult={boatSearchResult}
              loading={loading}
              isMobile={window.innerWidth <= 1190}
            />
            <FilterOrderBy
              orderBy={orderBy !== null ? orderBy : defaultValues.orderBy}
              onChange={(orderBy: OrderBy) => {
                if (lastFilterData)
                  submitSearch({ ...lastFilterData }, orderBy);
              }}
            />
            {loading ? (
              <SearchResultsLoader />
            ) : !boatSearchResult || boatSearchResultError ? (
              <ErrorSearch />
            ) : boatSearchResult.items.length === 0 ? (
              <NoResults />
            ) : (
              <>
                <SearchResults
                  boats={boatSearchResult!.items}
                  isIFrame={!!iFrameSetting}
                />
                <SearchPagination
                  lastPage={boatSearchResult!.lastPage}
                  currentPage={boatSearchResult!.currentPage}
                  onChange={(page: number) => {
                    submitSearch({ ...lastFilterData!, page: page });
                    window.scrollTo(0, 0);
                  }}
                />
              </>
            )}
          </div>
        </div>
      </div>

      <FulscreenMobileContainer
        title="FILTRY"
        onClose={() => {
          setMobileFilterOpen(false);
        }}
        icon={"icon-filter"}
        isHidden={!isMobileFilterOpen}
      >
        <div className="mobile filter">
          <Filter
            defaultFilterData={
              stateObj
                ? { ...defaultValues, ...stateObj }
                : lastFilterData
                ? lastFilterData
                : defaultValues
            }
            onFilterDataChanged={submitSearch}
            onFilterDataChangedMobile={(
              filterData: SearchFilterForm,
              orderBy?: OrderBy
            ) => {
              submitSearch(filterData, orderBy);
              setMobileFilterOpen(false);
            }}
            orderBy={orderBy}
            isMobile
          />
        </div>
      </FulscreenMobileContainer>

      {isMobileFilterOrderOpen && (
        <FulscreenMobileContainer
          title="ŘAZENÍ"
          onClose={() => {
            setMobileFilterOrder(false);
          }}
          icon={"icon-sort"}
        >
          <div className="mobile">
            <FilterOrderBy
              orderBy={orderBy !== null ? orderBy : defaultValues.orderBy}
              onChange={(orderBy: OrderBy) => {
                if (lastFilterData) {
                  submitSearch({ ...lastFilterData }, orderBy);
                  setMobileFilterOrder(false);
                  window.scrollTo({ top: 0, behavior: "smooth" });
                }
              }}
            />
          </div>
        </FulscreenMobileContainer>
      )}

      <div className="filter-mobile-bottom-bar">
        <Button
          text="FILTRY"
          icon="icon-filter"
          type="primary"
          size="large"
          customClass="filter-button left"
          onClick={() => setMobileFilterOpen(true)}
        />
        <Button
          text="ŘAZENÍ"
          icon="icon-sort"
          type="primary"
          size="large"
          customClass="filter-button right"
          onClick={() => setMobileFilterOrder(true)}
        />
        <CircleButton
          icon="icon-arrow_m"
          size="medium"
          customClass="button-to-up"
          onClick={() => window.scrollTo({ top: 0, behavior: "smooth" })}
        />
      </div>
    </>
  );
};
