import { OrderCreateResponse } from "../../types/entities/Order";

interface Props {
  response: OrderCreateResponse;
}

export const ReservationSuccess = ({ response }: Props) => {
  return (
    <div className="reservation-success">
      <h1>{response.message}</h1>
      <p>{response.subMessage}</p>
    </div>
  );
};
