interface Props {
  page: number;
  isActive: boolean;
  onClick: (page: number) => void;
}

export const SearchPaginationItem = ({ page, isActive, onClick }: Props) => {
  return (
    <div
      className={`pagination-item${isActive ? " active" : ""}`}
      onClick={() => (isActive ? undefined : onClick(page))}
    >
      {page}
    </div>
  );
};
