import { Footer } from "./footer/footer";
import { Grant } from "./footer/grant";
import { Header } from "./header/header";
import "./layout.styles.scss";
import "./grid.styles.scss";

interface Props {
  isIFrame?: boolean;
}

export const Layout = ({
  children,
  isIFrame,
}: React.PropsWithChildren<Props>) => {
  return (
    <>
      {!isIFrame && <Header />}
      <main className={`${isIFrame ? "iframe" : ""}`}>{children}</main>
      {!isIFrame && <Footer />}
      {!isIFrame && <Grant />}
    </>
  );
};
