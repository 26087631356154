import { Link, useNavigate } from "react-router-dom";
import { menuItems, routesPath } from "../../routes/routes";
import { footerCountries } from "../../types/entities/Country.types";
import { Wave } from "../../components/Wave/Wave";
import {
  MultiSelectItemType,
  MultiSelectItemTypeName,
} from "../../types/inputs/MultiSelect.types";

export const Footer = () => {
  const navigate = useNavigate();
  return (
    <footer>
      <div className="logo-text">
        <img
          src="/static/img/Logo/YachtHub_White_Horizontal_1x_svg.svg"
          title="Patička - logo"
          alt="Patička - logo"
        />
        <p>
          Sháníš pojištění pro svou plavbu? Rádi ti pomůžeme, neváhej nás
          kontaktovat.
        </p>
      </div>
      <div className="navigation">
        <div className="title">YachtHub</div>
        <nav>
          <ul>
            {menuItems.map((route, i) => {
              return (
                <li key={i}>
                  <Link to={route.link}>{route.text}</Link>
                </li>
              );
            })}
          </ul>
        </nav>
      </div>
      <div className="destinations">
        <div className="title">Oblibené destinace</div>
        <nav>
          <ul>
            {footerCountries.map((country, i) => {
              return (
                <li key={i}>
                  <span
                    className="country-link"
                    onClick={() => {
                      navigate(routesPath.SEARCH, {
                        state: {
                          filterData: JSON.stringify({
                            multiSelectItemsCountries: [
                              {
                                id: country.id,
                                name: country.name,
                                parentName: null,
                                iconName: country.iconName,
                                type: MultiSelectItemType.COUNTRY,
                                typeName: MultiSelectItemTypeName.COUNTRY,
                                url: null,
                                boatsCount: 0,
                              },
                            ],
                            countries: [country.id],
                          }),
                        },
                      });
                    }}
                  >
                    {country.name}
                  </span>
                </li>
              );
            })}
          </ul>
        </nav>
      </div>
      <div className="contact-container">
        <Wave count={10} color="blue" axis="y" className="vertical" />
        <div className="contact">
          <div className="title">POTŘEBUJEŠ PORADIT?</div>
          <div className="contact-item">
            <a href="tel:+420 777 644 491">+420 777 644 491</a>
          </div>
          <i className="icon-dash"></i>
          <div className="contact-item">
            <a href="mailto:info@yachthub.cz">INFO@YACHTHUB.CZ</a>
          </div>
          <div className="follow-us">
            <div className="contact-item">Sleduj nás</div>
            <div className="social">
              <a
                href="https://www.facebook.com/yachthub.cz"
                target="_blank"
                rel="noreferrer"
                title="Facebook"
              >
                <i className="icon-facebook"></i>
              </a>
              <a
                href="https://www.instagram.com/yachthub.cz"
                target="_blank"
                rel="noreferrer"
                title="Instagram"
              >
                <i className="icon-instagram"></i>
              </a>
              <a
                href="https://www.youtube.com/@yachthub8980"
                target="_blank"
                rel="noreferrer"
                title="Youtube"
              >
                <i className="icon-youtube"></i>
              </a>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};
