import "./buttons.styles.scss";

export interface Props {
  text?: string;
  title?: string;
  icon?: string;
  iconPosition?: "start" | "end";
  type?: "primary" | "light-blue" | "light" | "white" | "gold" | "yellow";
  size?: "small" | "medium" | "large" | "full-width";
  customClass?: string;
  disabled?: boolean;
  onClick: () => void;
}

export const Button = ({
  title,
  text,
  icon,
  iconPosition = "end",
  type = "primary",
  size = "small",
  customClass,
  disabled,
  onClick,
}: Props) => {
  return (
    <button
      title={title}
      className={`button ${disabled ? "disabled" : ""} ${type} ${size}${
        customClass ? ` ${customClass}` : ""
      }`}
      type="button"
      onClick={() => onClick()}
      disabled={disabled || false}
    >
      {!iconPosition ||
        (iconPosition === "end" ? (
          <>
            {text} <i className={icon}></i>
          </>
        ) : (
          <>
            <i className={icon} style={{ marginRight: "5px" }}></i> {text}
          </>
        ))}
    </button>
  );
};
