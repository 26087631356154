import React from "react";
import { BreadcrumbStepItem } from "../../types/breadcrumb/Breadcrumb.types";
import "./breadcrumb.styles.scss";
import { BreadcrumbItem } from "./BreadcrumbItem";

interface Props {
  items: BreadcrumbStepItem[];
}

export const Breadcrumb = ({ items }: Props) => {
  return (
    <div className="breadcrumb">
      {items.map((item, key) => {
        return (
          <React.Fragment key={key}>
            <BreadcrumbItem item={item} />{" "}
            {key + 1 < items.length ? <i className="icon-arrow_l"></i> : <></>}
          </React.Fragment>
        );
      })}
    </div>
  );
};
