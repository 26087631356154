import { Helmet } from "react-helmet";
import "./homePage.styles.scss";
import { WelcomeContainer } from "./WelcomeContainer";
import { OfferContainer } from "./OfferContainer";
import { CountriesContainer } from "./CountriesContainer";
import { AboutContainer } from "./AboutContainer";
import { InsuranceContainer } from "./InsuranceContainer";

export const HomePage = () => {
  return (
    <>
      <Helmet>
        <title>YachtHub | Pronájem lodí po celém světě</title>
        <meta
          name="description"
          content="Nový vítr do světa jachtingu. Sháníš loď pro svou dovolenou? Od nás bleskově dostaneš nabídku na míru. Pronajímáme lodě po celém světě."
        />
        {/* Twitter */}
        <meta
          name="twitter:card"
          content="https://yachthub.cz/static/img/Logo/Yachthub_Dark_Rectangle_1x_svg.svg"
        />
        <meta
          name="twitter:title"
          content="Twitter: YachtHub | Pronájem lodí po celém světě"
        />
        <meta
          name="twitter:description"
          content="TWITTER Nový vítr do světa jachtingu. Sháníš loď pro svou dovolenou? Od nás bleskově dostaneš nabídku na míru. Pronajímáme lodě po celém světě."
        />
        <meta
          name="twitter:image"
          content="https://yachthub.cz/static/img/Logo/Yachthub_Dark_Rectangle_1x_svg.svg"
        />
        {/* OG */}
        <meta
          property="og:title"
          content="OG: YachtHub | Pronájem lodí po celém světě"
        />
        <meta
          property="og:description"
          content="OG: Nový vítr do světa jachtingu. Sháníš loď pro svou dovolenou? Od nás bleskově dostaneš nabídku na míru. Pronajímáme lodě po celém světě."
        />
        <meta
          property="og:image"
          content="https://yachthub.cz/static/img/Logo/Yachthub_Dark_Rectangle_1x_svg.svg"
        />
        <meta property="og:url" content="yachthub.cz" />
        <meta
          property="og:site_name"
          content="OG sitename: YachtHub | Pronájem lodí po celém světě"
        />
      </Helmet>

      <div className="home-page">
        <WelcomeContainer />
        <OfferContainer />
        <CountriesContainer />
        <AboutContainer />
        <InsuranceContainer />
      </div>
    </>
  );
};
