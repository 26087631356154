import { useNavigate } from "react-router-dom";
import { BreadcrumbStepItem } from "../../types/breadcrumb/Breadcrumb.types";
import "./breadcrumb.styles.scss";

interface Props {
  item: BreadcrumbStepItem;
}

export const BreadcrumbItem = ({ item }: Props) => {
  const navigate = useNavigate();

  return (
    <div
      className={`breadcrumb-item${item.isCurrent ? " current" : ""}`}
      onClick={() => navigate(item.path)}
    >
      {item.name}
    </div>
  );
};
