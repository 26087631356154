import { useCallback } from "react";
import "./modal.styles.scss";
import { useOutsideClick } from "../../hooks/useOutsideClick";
import { CircleButton } from "../Buttons/CircleButton";

interface Props {
  visible: boolean;
  onClose: () => void;
}

export const Modal = ({
  children,
  visible,
  onClose,
}: React.PropsWithChildren<Props>) => {
  // Kliknutí mimo komponentu
  const handleOutsideClicked = useCallback(() => {
    onClose();
  }, []);
  const multiSelectRef = useOutsideClick(handleOutsideClicked);

  return (
    <div className={`modal${visible ? " visible" : ""}`}>
      <div className="modal-body">
        <CircleButton
          title={`Zavřít`}
          icon="icon-x_medium"
          size="medium"
          style="light"
          onClick={onClose}
          customClass="close-button"
        />
        <div ref={multiSelectRef} className="modal-content">
          {children}
        </div>
      </div>
    </div>
  );
};
