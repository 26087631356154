import { Link } from "react-router-dom";
import { useMenu } from "../../contexts/menuContext";
import { menuItems } from "../../routes/routes";
import { FulscreenMobileContainer } from "../fullscreen/fulscreenMobileContainer";
import "./menuMobile.styles.scss";

export const MenuMobile = () => {
  const { closeMobileMenu } = useMenu();

  return (
    <FulscreenMobileContainer
      title="MENU"
      icon="icon-menu"
      onClose={closeMobileMenu}
    >
      <div className="menu-mobile">
        <nav>
          <ul>
            {menuItems.map((route, i) => {
              return (
                <li key={i}>
                  <Link onClick={closeMobileMenu} to={route.link}>
                    {route.text}
                  </Link>
                </li>
              );
            })}
          </ul>
        </nav>
      </div>
    </FulscreenMobileContainer>
  );
};
