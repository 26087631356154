import { Link } from "react-router-dom";
import { menuItems } from "../../routes/routes";

export const Menu = () => {
  return (
    <div className="menu">
      <nav>
        <ul>
          {menuItems.map((route, i) => {
            return (
              <li key={i}>
                <Link to={route.link}>{route.text}</Link>
              </li>
            );
          })}
        </ul>
      </nav>
    </div>
  );
};
