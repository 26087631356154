import {
  MultiSelectItem,
  MultiSelectItemType,
} from "../../../types/inputs/MultiSelect.types";
import { CountryIcon } from "../../CountryIcon/CountryIcon";
import { CircleButton } from "../../Buttons/CircleButton";
import {
  findStringPosition,
  removeDiacritics,
} from "../../../functions/string.functions";

interface Props {
  multiSelectItem: MultiSelectItem;
  showRemoveButton?: boolean;
  disableTypeName?: boolean;
  query?: string;
  onSelect: (id: number, type: MultiSelectItemType) => void;
  onRemove?: (id: number, type: MultiSelectItemType) => void;
}

export const MultiSelectResultItem = ({
  multiSelectItem,
  showRemoveButton,
  disableTypeName,
  query,
  onSelect,
  onRemove,
}: Props) => {
  const highlightQuery = (name: string) => {
    if (!query) return name;

    const startEnd = findStringPosition(
      removeDiacritics(name.toLocaleLowerCase()),
      removeDiacritics(query.toLocaleLowerCase())
    );
    if (!startEnd) return name;

    const char1 = '<span class="highlight-query">';
    const char2 = "</span>";

    // Vloží první znak na pozici pos1
    const tempString =
      name.slice(0, startEnd.start) + char1 + name.slice(startEnd.start);

    // Upravená druhá pozice (posun o délku prvního znaku)
    const adjustedSecondPos = startEnd.end + 1 + char1.length;

    // Vloží druhý znak na pozici pos2
    return (
      tempString.slice(0, adjustedSecondPos) +
      char2 +
      tempString.slice(adjustedSecondPos)
    );
  };

  return (
    <div
      className="mutliselect-result-item"
      key={multiSelectItem.id}
      onClick={() => onSelect(multiSelectItem.id, multiSelectItem.type)}
    >
      <div className="content">
        {multiSelectItem.iconName && (
          <CountryIcon
            iconName={multiSelectItem.iconName}
            countryName={multiSelectItem.name}
          />
        )}
        <span
          dangerouslySetInnerHTML={{
            __html: highlightQuery(multiSelectItem.name),
          }}
        />
        {!disableTypeName && (
          <span className="type">{multiSelectItem.typeName}</span>
        )}
      </div>
      {showRemoveButton && onRemove && (
        <CircleButton
          title={`Odebrat ${multiSelectItem.name}`}
          icon="icon-x_medium"
          onClick={() => onRemove(multiSelectItem.id, multiSelectItem.type)}
        />
      )}
    </div>
  );
};
