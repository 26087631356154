import "./preparation.styles.scss";

export const Preparation = () => {
  return (
    <div className="preparation">
      <p className="headline">Připravujeme pro tebe</p>
      <p>
        Tuto službu pro tebe zatím připravujeme. V případě dotazů se neváhejte
        ozvat.
      </p>
    </div>
  );
};
