interface Props {
  count: number;
  color: "blue" | "gold";
  height?: number;
  axis?: "x" | "y";
  style?: any;
  className?: string;
}

export const Wave = ({
  count,
  color,
  height = 16,
  axis = "x",
  style,
  className,
}: Props) => {
  const width = height * (25 / 12);

  const fill = {
    blue: "#234E9C",
    gold: "#DCB05B",
  };

  return (
    <div className={`wave${className ? ` ${className}` : ``}`} style={style}>
      {Array(count)
        .fill(1)
        .map((numb, key) => (
          <svg
            key={key}
            viewBox="0 0 25 12"
            fill={fill[color]}
            width={width}
            height={height}
            xmlns="http://www.w3.org/2000/svg"
            style={
              axis === "x"
                ? { marginRight: `-${width * 0.07}px` }
                : { marginBottom: `${width * 0.45}px` }
            }
          >
            <path d="M18.155 6.17922C18.155 9.38881 20.698 12 23.825 12C24.55 11.6667 25.565 10.808 23.825 10.0399C21.751 10.0399 20.064 8.30794 20.064 6.17922C20.064 4.5026 21.106 3.02718 22.658 2.5076C23.105 2.35765 23.404 1.93272 23.402 1.4501C23.399 0.96889 23.098 0.54802 22.651 0.40283C17.6016 -1.23929 12.8662 2.45923 8.68756 5.72284L8.686 5.72405C5.84501 7.94349 3.16099 10.0399 0.954987 10.0399C0.426987 10.0399 0 10.4787 0 11.0199C0 11.5612 0.426987 12 0.954987 12C3.80499 12 6.73799 9.7093 9.84299 7.2839L9.84449 7.28272C13.137 4.71097 16.5215 2.06739 19.908 1.96506C18.812 3.0381 18.155 4.54531 18.155 6.17922Z" />
          </svg>
        ))}
    </div>
  );
};
