import axios, { AxiosRequestConfig } from "axios";
import { handleApiErrors } from "./apiErrorHandler";

// @ts-ignore
export const fetcher = async (url) => {
  const res = await fetch(url);

  // If the status code is not in the range 200-299,
  // we still try to parse and throw it.
  if (!res.ok) {
    const error: any = new Error("An error occurred while fetching the data.");
    error.status = res.status;
    throw error;
  }

  return res.json();
};

const baseClient = axios.create({
  //baseURL: "https://localhost:7113/api",
  baseURL: "https://app.yachthub.cz/api/",
});

const baseClientFiles = axios.create({
  baseURL: "",
  headers: {
    "Content-Type": "multipart/form-data",
    Accept: "*/*",
  },
});

interface ApiBaseParams {
  url: string;
  config?: AxiosRequestConfig | null;
}

interface ApiGetParams extends ApiBaseParams {
  params?: object | null;
  showNotificationOnErr?: boolean;
  isOnline?: boolean;
}

interface ApiPostParams extends ApiBaseParams {
  data?: object | null;
  showNotificationOnErr?: boolean;
  isOnline?: boolean;
  returnError?: boolean;
}

interface ApiPutParams extends ApiBaseParams {
  data?: object | null;
  showNotificationOnErr?: boolean;
  isOnline?: boolean;
}

interface ApiDeleteParams extends ApiBaseParams {
  params?: object | null;
  showNotificationOnErr?: boolean;
  isOnline?: boolean;
}

const apiGet = async ({
  url,
  config,
  showNotificationOnErr = true,
}: ApiGetParams) => {
  try {
    const response = await baseClient.get(url, {
      ...config,
    });
    return response;
  } catch (err) {
    handleApiErrors(err, showNotificationOnErr);
    return null;
  }
};

const apiPost = async ({
  url,
  data,
  config,
  showNotificationOnErr = true,
  returnError = false,
}: ApiPostParams) => {
  try {
    const response = await baseClient.post(url, data, {
      ...config,
    });
    return response;
  } catch (err) {
    handleApiErrors(err, showNotificationOnErr);
    return null;
  }
};

const apiPostFiles = async ({
  url,
  data,
  config,
  showNotificationOnErr = true,
}: ApiPostParams) => {
  try {
    const response = await baseClientFiles.post(url, data, {
      ...config,
    });

    return response;
  } catch (err) {
    handleApiErrors(err, showNotificationOnErr);
    return null;
  }
};

const apiPut = async ({
  url,
  data,
  config,
  showNotificationOnErr = true,
}: ApiPutParams) => {
  try {
    const response = await baseClient.put(url, data, {
      ...config,
    });
    return response;
  } catch (err) {
    handleApiErrors(err, showNotificationOnErr);
    return null;
  }
};

const apiDelete = async ({
  url,
  config,
  showNotificationOnErr = true,
}: ApiDeleteParams) => {
  try {
    const response = await baseClient.delete(url, {
      ...config,
    });
    return response;
  } catch (err) {
    handleApiErrors(err, showNotificationOnErr);
    return null;
  }
};

export const postHeaders = {
  headers: { "Content-Type": "application/json" },
  withCredentials: true,
};

const defaultApi = {
  baseClient,
  apiGet,
  apiPost,
  apiPut,
  apiDelete,
  apiPostFiles,
};
export default defaultApi;
