import { useLocation, useNavigate, useParams } from "react-router-dom";
import "./reservationPage.styles.scss";
import { Helmet } from "react-helmet";
import { useCallback, useEffect, useState } from "react";
import { BoatDetail } from "../../types/entities/Boat.types";
import { apiUrl } from "../../api/apiUrl";
import axios from "../../api/axios";
import { Breadcrumb } from "../../components/Breadcrumb/Breadcrumb";
import { routesPath } from "../../routes/routes";
import { TermWithMessage } from "../../types/entities/Term.types";
import { Button } from "../../components/Buttons/Button";
import { TextBox } from "../../components/inputs/TextBox/TextBox";
import { Loader } from "../../components/Loader/Loader";
import { BoatInfo } from "./BoatInfo";
import { OrderCreateResponse } from "../../types/entities/Order";
import { ReservationSuccess } from "./ReservationSuccess";
import { BackButton } from "../../components/BackButton/BackButton";

interface FormData {
  firstName?: string;
  lastName?: string;
  email?: string;
  phone?: string;
  note?: string;
}

export const ReservationPage = () => {
  const location = useLocation();
  const isIFrame = location.pathname.split("/")[1] === "iframe";
  const navigate = useNavigate();
  const { boatUrl, termId: urlTermId } = useParams();

  const [boat, setBoat] = useState<BoatDetail | null>(null);
  const [termWithMessage, setTermWithMessage] =
    useState<TermWithMessage | null>(null);
  const [formData, setFormData] = useState<FormData>({});
  const [errors, setErrors] = useState<string | null>(null);
  const [isCreatingOrder, setCreatingOrder] = useState(false);
  const [successOrder, setSuccess] = useState<OrderCreateResponse | null>(null);

  const fetchBoat = useCallback(async () => {
    const result = await axios.apiGet({
      url: `${apiUrl.BOAT_BY_URL}?url=${boatUrl}`,
    });

    if (result?.status === 200) setBoat(result.data);
  }, [boatUrl]);

  const fetchTerm = useCallback(
    async (termId: number) => {
      const result = await axios.apiGet({
        url: `${apiUrl.TERM_ITEM}?id=${termId}`,
      });
      if (result?.status === 200) setTermWithMessage(result.data);
    },
    [boat]
  );

  const submitForm = useCallback(async () => {
    setCreatingOrder(true);
    if (!termWithMessage) return;

    if (
      !formData.firstName ||
      formData.firstName.trim() === "" ||
      !formData.lastName ||
      formData.lastName.trim() === "" ||
      !formData.email ||
      formData.email.trim() === "" ||
      !formData.phone ||
      formData.phone.trim() === ""
    ) {
      setErrors("Vyplň povinné údaje.");
      setCreatingOrder(false);
      return;
    }

    const result = await axios.apiPost({
      url: `${apiUrl.ORDER_CREATE}`,
      data: { ...formData, termId: termWithMessage.term.id, isIFrame },
      returnError: true,
    });

    if (!result) {
      setErrors(
        "Došlo k chybě. Zkus to prosím znovu nebo nás prosím kontaktuj."
      );
      setCreatingOrder(false);
      return;
    }

    if (!result.data.isOk) {
      setErrors(result.data.message);
      setCreatingOrder(false);
      return;
    }

    setSuccess(result.data);
    setCreatingOrder(false);
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, [formData, termWithMessage]);

  useEffect(() => {
    fetchBoat();
  }, []);

  useEffect(() => {
    if (boat) fetchTerm(Number(urlTermId));
  }, [boat]);

  if (!boat) return <Loader text="Načítáme loď" />;
  if (!termWithMessage) return <Loader text="Ověřujeme cenu" />;

  return (
    <>
      <Helmet>
        <title>YachtHub | Rezervace - {boat.name}</title>
      </Helmet>

      <div className="page">
        {!isIFrame ? (
          <Breadcrumb
            items={[
              { name: "Domů", path: routesPath.HOME },
              {
                name: "Pronájem lodí",
                path: routesPath.SEARCH,
              },
              {
                name: boat.name,
                path: `${routesPath.BOAT}/${boatUrl}/${termWithMessage?.term.id}`,
              },
              {
                name: "Nezávazná rezervace",
                path: routesPath.RESERVATION,
                isCurrent: true,
              },
            ]}
          />
        ) : (
          <BackButton
            onClick={() => {
              navigate(
                `${routesPath.IFRAME_BOAT}/${boat.url}/${termWithMessage?.term.id}`
              );
            }}
          />
        )}
        <div className="reservation-page">
          {successOrder ? (
            <ReservationSuccess response={successOrder} />
          ) : (
            <>
              <div className="right-column mobile">
                <BoatInfo boat={boat} termWithMessage={termWithMessage} />
              </div>
              <div className="left-column">
                <div className="card">
                  <h2>Kontaktní údaje</h2>
                  <div className="form">
                    <div className="form-row">
                      <div className="form-input-6">
                        <TextBox
                          type="text"
                          name="firstName"
                          label="Jméno"
                          placeholder="Zadej jméno"
                          value={formData.firstName}
                          isError={!formData.firstName && !!errors}
                          onChange={(value: string) => {
                            setFormData({ ...formData, firstName: value });
                          }}
                        />
                      </div>
                      <div className="form-input-6">
                        <TextBox
                          type="text"
                          name="lastName"
                          label="Příjmení"
                          placeholder="Zadej příjmení"
                          value={formData.lastName}
                          isError={!formData.lastName && !!errors}
                          onChange={(value: string) => {
                            setFormData({ ...formData, lastName: value });
                          }}
                        />
                      </div>

                      <div className="form-input-6">
                        <TextBox
                          type="text"
                          name="email"
                          label="Email"
                          placeholder="Zadej email"
                          value={formData.email}
                          isError={!formData.email && !!errors}
                          onChange={(value: string) => {
                            setFormData({ ...formData, email: value });
                          }}
                        />
                      </div>
                      <div className="form-input-6">
                        <TextBox
                          type="text"
                          name="phone"
                          label="Telefonní číslo"
                          placeholder="Zadej telefonní číslo"
                          value={formData.phone}
                          isError={!formData.phone && !!errors}
                          onChange={(value: string) => {
                            setFormData({ ...formData, phone: value });
                          }}
                        />
                      </div>

                      <div className="form-input-12">
                        <TextBox
                          type="text"
                          name="note"
                          label="Poznámka"
                          placeholder="Zadej poznámku"
                          value={formData.note}
                          onChange={(value: string) => {
                            setFormData({ ...formData, note: value });
                          }}
                          multiline
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="card">
                  <h2>Shrnutí ceny</h2>
                  {termWithMessage.term.isDiscount && (
                    <div className="price-row">
                      <div className="label">Původní cena</div>
                      <div className="value">
                        <span className="discount">
                          {termWithMessage.term.discountPercentage}
                        </span>
                        <span className="price-before">
                          {termWithMessage.term.priceBefore}
                        </span>
                      </div>
                    </div>
                  )}
                  <div className="price-row">
                    <div className="label">Tvoje cena</div>
                    <div className="value">
                      <span className="price">
                        {termWithMessage.term.price}
                      </span>
                    </div>
                  </div>
                  {boat.deposit && (
                    <div className="price-row">
                      <div className="label">Kauce</div>
                      <div className="value">{boat.deposit}</div>
                    </div>
                  )}
                </div>
                {errors && <div className="error-message">{errors}</div>}
                <Button
                  title={
                    isCreatingOrder ? "Odesílá se..." : "Nezávazně rezervovat"
                  }
                  text={
                    isCreatingOrder ? "Odesílá se..." : "Nezávazně rezervovat"
                  }
                  size="full-width"
                  type="gold"
                  customClass="btn-reservation"
                  onClick={submitForm}
                  disabled={isCreatingOrder}
                />
                {termWithMessage && (
                  <div className="term-message">
                    {termWithMessage.isAvaliable ? (
                      <i className="icon-check_medium available"></i>
                    ) : (
                      <i className="icon-error not-available"></i>
                    )}
                    {termWithMessage.message}
                  </div>
                )}
                <div className="terms-message">
                  Kliknutím na tlačítko souhlasíš s:{" "}
                  <span className="doc-link">
                    Obchodní podmínky a ochrana osobních údajů
                  </span>
                </div>
              </div>
              <div className="right-column desktop">
                <BoatInfo boat={boat} termWithMessage={termWithMessage} />
              </div>
            </>
          )}
        </div>
      </div>
    </>
  );
};
