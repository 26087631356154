import { AxiosError } from "axios";
// import { showToast } from "../functions/toast";
// import { ApiResultTranslations } from "../functions/Translation.function";

export const handleApiErrors = (
  err: AxiosError | Error | unknown,
  showNotificationOnErr: boolean
) => {
  if (!showNotificationOnErr) return;

  // No server response
  if (err instanceof AxiosError) {
    if (!err?.response) {
      // showToast("error", "Error");
      return;
    } else {
      const e: any = err;
      if (e?.response?.data) {
        // const translation: string =
        //   // @ts-ignore
        //   ApiResultTranslations[e?.response?.data as string];
        // if (translation) showToast("error", translation);
        // else showToast("error", e?.response?.data);
      } else if (e?.message) {
        // showToast("error", e.message);
      } else {
        // showToast("error", "Error");
      }
    }
  } else {
    // showToast("error", "Error");
  }
};
