import { useCallback, useEffect, useRef, useState } from "react";
import "./multiSelect.styles.scss";
import axios from "../../../api/axios";
import { apiUrl } from "../../../api/apiUrl";
import {
  MultiSelectItem,
  MultiSelectItemType,
} from "../../../types/inputs/MultiSelect.types";
import { MultiSelectResults } from "./MultiSelectResults";
import { useOutsideClick } from "../../../hooks/useOutsideClick";
import { MultiSelectedInputSelected } from "./MultiSelectedInputSelected";
import { FulscreenMobileContainer } from "../../../layout/fullscreen/fulscreenMobileContainer";
import { MultiSelectResultItem } from "./MultiSelectResultItem";
import { useNavigate } from "react-router-dom";
import { routesPath } from "../../../routes/routes";

interface SelectContent {
  all: MultiSelectItem[];
  selected: MultiSelectItem[];
}

interface Props {
  name: string;
  icon?: string;
  title: string;
  placeholder: string;
  type: MultiSelectItemType | null;
  defaultValues: MultiSelectItem[];
  selectedEntities: MultiSelectItem[];
  isMobile?: boolean;
  resultsWidth?: number;
  showResulsUnderInput?: boolean;
  isLocalDataSource?: boolean;
  localDataSource?: MultiSelectItem[];
  onChange: (selectedMultiSelectItems: MultiSelectItem[]) => void;
  onInputFocusedChange?: (isFocused: boolean) => void;
}

export const MultiSelect2 = ({
  name,
  icon,
  title,
  placeholder,
  type,
  defaultValues,
  selectedEntities,
  isMobile,
  resultsWidth,
  showResulsUnderInput,
  isLocalDataSource,
  localDataSource,
  onChange,
  onInputFocusedChange,
}: Props) => {
  const navigate = useNavigate();
  // Reference na textový input, při kliknutí na celou komponentu je třeba nastavit focus
  const inputRef = useRef<HTMLInputElement>(null);
  // Loading kvůli volání API
  const [loading, setLoading] = useState(false);
  // Indikátor, že je focus na celou komponentu - jen potom jsou vidět results
  const [isFocused, setFocused] = useState(false);
  // Vyhledávací řetězec
  const [inputValue, setInputValue] = useState("");
  // Nabízené položky
  const [multiSelectItems, setMultiSelectItems] = useState<SelectContent>({
    all: [],
    selected: defaultValues ? defaultValues : [],
  });

  useEffect(() => {
    setMultiSelectItems({
      ...multiSelectItems,
      selected: defaultValues.filter((x) => type === null || x.type === type),
    });
  }, [defaultValues]);

  // Volání API
  const fetchResults = async () => {
    setLoading(true);

    if (isLocalDataSource) {
      const results = localDataSource!.filter(
        (x) =>
          inputValue === "" ||
          x.name.toLocaleLowerCase().includes(inputValue.toLocaleLowerCase())
      );
      setMultiSelectItems((prevData) => ({
        ...prevData,
        all: results,
      }));
    } else {
      const result = await axios.apiPost({
        url: `${apiUrl.MULTI_SELECT_ITEM_LIST}`,
        data: {
          query: inputValue,
          top: 10,
          type: type,
          selectedEntities: selectedEntities,
        },
      });
      if (result?.status === 200) {
        setMultiSelectItems((prevData) => ({
          ...prevData,
          all: result.data,
        }));
      }
    }

    setLoading(false);
  };

  // Query se nastaví až po 0,5s, aby se API nevolalo ihned
  useEffect(
    function debounceQuery() {
      const timeoutId = setTimeout(() => fetchResults(), 500);
      return () => {
        clearTimeout(timeoutId);
      };
    },
    [inputValue]
  );

  // Kliknutí mimo komponentu
  const handleOutsideClicked = useCallback(() => {
    setInputValue("");
    setFocused(false);
  }, []);
  const multiSelectRef = useOutsideClick(handleOutsideClicked);

  //#region handlers

  // Vybrání položky
  const handleMultiSelectItemSeleted = useCallback(
    (id: number, type: MultiSelectItemType) => {
      if (!multiSelectItems.all) return;

      const selected = multiSelectItems.all.find(
        (x) => x.id === id && x.type === type
      );

      if (!selected) return;

      if (selected.type === MultiSelectItemType.BOAT) {
        navigate(`${routesPath.BOAT}/${selected.url}`);
        return;
      }

      // Item se již v seznamu vybrnaých může nacházet
      const isInSelected = multiSelectItems.selected?.find(
        (x) => x.id === id && x.type === type
      );
      if (isInSelected) return;

      // Item odebereme z nabízených
      const _all = multiSelectItems.all.filter(
        (x) => !(x.id === id && x.type === type)
      );

      // Přidání itemu do seznamu vybraných
      const _selected = multiSelectItems.selected
        ? [...multiSelectItems.selected, selected]
        : [selected];

      const _multiselectItems = {
        all: _all,
        selected: _selected,
      };

      setMultiSelectItems(_multiselectItems);
      onChange(_multiselectItems.selected);
      setInputValue("");
    },
    [multiSelectItems]
  );

  // Odebrání položky
  const handleMultiSelectItemRemoved = useCallback(
    (id: number, type: MultiSelectItemType) => {
      if (!multiSelectItems.selected) return;

      const toRemove = multiSelectItems.selected?.find(
        (x) => x.id === id && x.type === type
      );
      if (!toRemove) return;

      // Odebereme z vybraných
      const _selected = multiSelectItems.selected.filter(
        (x) => !(x.id === id && x.type === type)
      );

      // Přidáme do nabízených - teoreticky by se tam mohl již nacházet, provedeme kontrolu
      const isInAll = multiSelectItems.all.some(
        (x) => x.type === toRemove.type && x.id === toRemove.id
      );

      let _all = multiSelectItems.all;
      if (!isInAll)
        _all = multiSelectItems.all
          ? [...multiSelectItems.all, toRemove]
          : [toRemove];

      //_all = _all.sort((a, b) => a.name.localeCompare(b.name));

      setMultiSelectItems({
        all: _all,
        selected: _selected,
      });
      onChange(_selected);
    },
    [multiSelectItems]
  );

  // Odebrání všech položek
  const handleMultiSelectItemRemovedAll = useCallback(() => {
    if (!multiSelectItems.selected) return;

    // V ALL mohou být itemy, které jsou i v selected, do ALL tedy přidáme jen ty, které tam zatím nejsou
    const selectedNotInAll = multiSelectItems.selected.filter(
      (x: MultiSelectItem) => !multiSelectItems.all.some((y) => y.id === x.id)
    );

    // Přidání itemu do seznamu nabízených
    const _all = multiSelectItems.all
      ? [...multiSelectItems.all, ...selectedNotInAll]
      : [...selectedNotInAll];

    setMultiSelectItems({
      all: _all,
      selected: [],
    });
    onChange([]);
  }, [multiSelectItems]);

  //#endregion

  useEffect(() => {
    if (onInputFocusedChange) onInputFocusedChange(isFocused);
    if (isFocused) {
      fetchResults();
      if (!isMobile && inputRef.current) inputRef.current.focus();
    }
  }, [isFocused]);

  return (
    <>
      <div
        ref={multiSelectRef}
        className={`multi-select${isMobile ? ` multi-select-mobile` : ""}`}
        onClick={() => {
          if (isFocused && isMobile) return;
          setFocused(true);
          if (!inputRef || !inputRef.current || isMobile) return;
          inputRef.current.focus();
        }}
      >
        {(!isMobile || !isFocused) && (
          <div className="enter-zone">
            {icon && <i className={icon}></i>}
            <div className="input">
              <label className={"active"}>{title}</label>
              <div className="input-with-celected-values">
                <MultiSelectedInputSelected
                  selectedMultiSelectItems={multiSelectItems.selected}
                  showResulsUnderInput={showResulsUnderInput}
                />
                <input
                  ref={inputRef}
                  type="text"
                  title={name}
                  value={inputValue}
                  placeholder={placeholder}
                  onChange={(e: React.FormEvent<HTMLInputElement>) =>
                    setInputValue(e.currentTarget.value)
                  }
                />
              </div>
            </div>
          </div>
        )}

        {isFocused && !isMobile && (
          <MultiSelectResults
            isLoading={loading}
            multiSelectItems={multiSelectItems.all}
            selectedMultiSelectItems={multiSelectItems.selected}
            onSelect={handleMultiSelectItemSeleted}
            onRemove={handleMultiSelectItemRemoved}
            onRemoveAll={handleMultiSelectItemRemovedAll}
            disableTypeName={false}
            width={resultsWidth}
            query={inputValue}
          />
        )}

        {isFocused && isMobile && (
          <FulscreenMobileContainer
            title={title}
            onClose={() => {
              setFocused(false);
            }}
            icon={icon}
          >
            <div className="mobile">
              <div className="input">
                <input
                  ref={inputRef}
                  type="text"
                  title={name}
                  value={inputValue}
                  placeholder={placeholder}
                  onChange={(e: React.FormEvent<HTMLInputElement>) =>
                    setInputValue(e.currentTarget.value)
                  }
                  onFocus={() => {
                    setFocused(true);
                  }}
                />
              </div>
              <MultiSelectResults
                isLoading={loading}
                multiSelectItems={multiSelectItems.all}
                selectedMultiSelectItems={multiSelectItems.selected}
                onSelect={handleMultiSelectItemSeleted}
                onRemove={handleMultiSelectItemRemoved}
                onRemoveAll={handleMultiSelectItemRemovedAll}
                // disableTypeName={disableTypeName}
                width={resultsWidth}
                query={inputValue}
              />
            </div>
          </FulscreenMobileContainer>
        )}
      </div>
      {showResulsUnderInput && (
        <div className="results-under-input">
          {multiSelectItems.selected.map(
            (multiSelectItem: MultiSelectItem, i) => {
              return (
                <MultiSelectResultItem
                  key={i}
                  multiSelectItem={multiSelectItem}
                  onSelect={handleMultiSelectItemSeleted}
                  onRemove={handleMultiSelectItemRemoved}
                  disableTypeName={false}
                  showRemoveButton
                />
              );
            }
          )}
        </div>
      )}
    </>
  );
};
