import "./buttons.styles.scss";

export interface Props {
  text?: string;
  title?: string;
  icon?: string;
  size?: `small` | `medium` | "large";
  style?: `dark` | `light`;
  customClass?: string;
  onClick: () => void;
}

export const CircleButton = ({
  title,
  icon,
  onClick,
  size = `small`,
  style = `dark`,
  customClass,
}: Props) => {
  return (
    <button
      title={title}
      className={`circle-button ${size} ${style} ${customClass}`}
      type="button"
      onClick={onClick}
    >
      <i className={icon}></i>
    </button>
  );
};
