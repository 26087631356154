import moment, { Moment } from "moment";
import { BoatSearch } from "../entities/Boat.types";
import {
  MultiSelectItem,
  MultiSelectItemType,
} from "../inputs/MultiSelect.types";

export enum OrderBy {
  RECOMMENDED,
  CHEAPEST,
  MOST_EXPENSIVE,
  LARGEST_DISCOUNT,
}

export interface SearchFilterForm {
  countries: number[];
  regions: number[];
  bases: number[];
  charters: number[];

  dateFrom: Moment | null;
  dateTo: Moment | null;

  boatCategories: number[];
  berthsFrom: number;
  berthsTo: number;
  priceFrom: number;
  priceTo: number;
  lengthFrom: number;
  lengthTo: number;
  yearOfManufactureFrom: number;
  yearOfManufactureTo: number;
  cabinsFrom: number;
  cabinsTo: number;

  orderBy: OrderBy;
  page: number;

  multiSelectItemsCountries: MultiSelectItem[];
  multiSelectItemsRegions: MultiSelectItem[];
  multiSelectItemsBases: MultiSelectItem[];
  multiSelectItemsCharters: MultiSelectItem[];
  multiSelectItemsBoatCategories: MultiSelectItem[];
}

export const defaultValues = {
  countries: [],
  regions: [],
  bases: [],
  charters: [],

  dateFrom: null,
  dateTo: null,

  boatCategories: [],
  berthsFrom: 0,
  berthsTo: 20,
  priceFrom: 300,
  priceTo: 8000,
  lengthFrom: 5,
  lengthTo: 55,
  yearOfManufactureFrom: 2000,
  yearOfManufactureTo: moment().year() + 1,
  cabinsFrom: 1,
  cabinsTo: 6,

  orderBy: OrderBy.RECOMMENDED,
  page: 0,

  multiSelectItemsCountries: [],
  multiSelectItemsRegions: [],
  multiSelectItemsBases: [],
  multiSelectItemsCharters: [],
  multiSelectItemsBoatCategories: [],
};

export interface SearchFilterResult {
  currentPage: number;
  items: BoatSearch[];
  itemsCount: number;
  itemsPerPage: number;
  lastPage: number;
}

const boatCategoryTemplate = {
  type: MultiSelectItemType.BOAT_CATEGORY,
  typeName: "Typ lodě",
  iconName: null,
};

export const boatCategories: MultiSelectItem[] = [
  {
    id: 50,
    name: "Plachetnice",
    ...boatCategoryTemplate,
  },
  {
    id: 29,
    name: "Katamarán",
    ...boatCategoryTemplate,
  },
  {
    id: 48,
    name: "Motorová jachta",
    ...boatCategoryTemplate,
  },
  {
    id: 35,
    name: "Motorový katamarán",
    ...boatCategoryTemplate,
  },
  {
    id: 28,
    name: "Motorová loď",
    ...boatCategoryTemplate,
  },
  {
    id: 42,
    name: "House boat",
    ...boatCategoryTemplate,
  },
  {
    id: 30,
    name: "Gulet",
    ...boatCategoryTemplate,
  },
];
