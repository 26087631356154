import { useNavigate } from "react-router-dom";
import { Button } from "../../components/Buttons/Button";
import "./homePage.styles.scss";
import { routesPath } from "../../routes/routes";

export const AboutContainer = () => {
  const navigate = useNavigate();
  return (
    <div className="service-container">
      <div className="service">
        <div className="content-container">
          <div className="content about">
            <div className="title">CO JE #YACHTHUB?</div>
            <p>
              YachtHub je centrem pro všechny jachtaře a námořníky. Takový
              přístav v kyberprostoru, kde seženeš všechno potřebné pro své
              plavby.
            </p>
            <Button
              title="Zobrazit více - co je YachtHub"
              text="Zobrazit více"
              type="primary"
              size="large"
              onClick={() => navigate(routesPath.ABOUT)}
            />
          </div>
        </div>
        <div className="image">
          <img
            src="/static/img/Pics/pic_yachthub_1x_png.png"
            title="Co je YachtHub obrázek"
            alt="Co je YachtHub obrázek"
          />
        </div>
      </div>
    </div>
  );
};
