import { Moment } from "moment";
import { MultiSelectItem } from "../inputs/MultiSelect.types";

export interface HomepageFilterForm {
  countries: number[];
  regions: number[];
  bases: number[];
  charters: number[];

  dateFrom: Moment | null;
  dateTo: Moment | null;

  multiSelectItemsCountries: MultiSelectItem[];
  multiSelectItemsRegions: MultiSelectItem[];
  multiSelectItemsBases: MultiSelectItem[];
  multiSelectItemsCharters: MultiSelectItem[];
  multiSelectItemsBoatCategories: MultiSelectItem[];
}

export const defaultValues = {
  countries: [],
  regions: [],
  bases: [],
  charters: [],

  dateFrom: null,
  dateTo: null,

  multiSelectItemsCountries: [],
  multiSelectItemsRegions: [],
  multiSelectItemsBases: [],
  multiSelectItemsCharters: [],
  multiSelectItemsBoatCategories: [],
};
