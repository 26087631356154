import { useNavigate } from "react-router-dom";
import { CircleButton } from "../Buttons/CircleButton";
import "./backButton.styles.scss";

interface Props {
  onClick?: () => void;
}

export const BackButton = ({ onClick }: Props) => {
  const navigate = useNavigate();
  return (
    <div
      className="back-button"
      onClick={() => (onClick ? onClick() : navigate(-1))}
    >
      <CircleButton
        onClick={() => navigate(-1)}
        style="light"
        icon="icon-arrow_l"
        size="medium"
      />
      <div className="title">Zpět</div>
    </div>
  );
};
