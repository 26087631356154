export const SearchResultsLoader = () => {
  return (
    <div className="search-result">
      {Array(10)
        .fill(1)
        .map((numb, key) => (
          <div key={key} className="boat-card loader">
            <div className="main-image"></div>
            <div className="content">
              <div className="boat-name loader-content"></div>
              <span className="boat-location loader-content"></span>
              <div className="boat-tags">
                <div className="tag loader-content"></div>
                <div className="tag loader-content"></div>
                <div className="tag loader-content"></div>
              </div>
              <div className="date loader-content"></div>
              <div className="footer">
                <div className="price loader-content"></div>
                <div className="price loader-content"></div>
                <div className="price loader-content"></div>
                <div className="price loader-content"></div>
              </div>
            </div>
          </div>
        ))}
    </div>
  );
};
