import { Helmet } from "react-helmet";
import { Breadcrumb } from "../../components/Breadcrumb/Breadcrumb";
import { routesPath } from "../../routes/routes";
import { ButtonLink } from "../../components/Buttons/ButtonLink";

export const ContactPage = () => {
  return (
    <>
      <Helmet>
        <title>YachtHub | Kontakt</title>
      </Helmet>

      <div className="page">
        <Breadcrumb
          items={[
            { name: "Domů", path: routesPath.HOME },
            {
              name: "Kontakt",
              path: routesPath.CONTACT,
              isCurrent: true,
            },
          ]}
        />

        <h1>Kontaktuj nás</h1>
        <div style={{ display: "flex", gap: "15px" }}>
          <ButtonLink
            title="+420 777 644 491"
            text="+420 777 644 491"
            type="yellow"
            size="large"
            href="tel:+420 777 644 491"
          />
          <ButtonLink
            title="info@yachthub.cz"
            text="info@yachthub.cz"
            type="gold"
            size="large"
            href="mailto:info@yachthub.cz"
          />
        </div>
        <div className="row" style={{ marginTop: "40px" }}>
          <div className="col-6">
            <h2>Informace o firmě</h2>
            <hr className="divider" />
            <p>
              Pardubice 530 02 - Zelené Předměstí
              <br />
              náměstí Republiky 2686
              <br />
              Česká Republika
              <br />
              <br />
              IČ: 09744801
              <br />
              DIČ: CZ09744801
              <br />
              Spisová značka: C 46815 vedená u Krajského soudu v Hradci Králové
              <br />
              <br />
              Telefon: +420 777 644 491
              <br />
              E-mail: info@yachthub.cz
            </p>
          </div>
          <div className="col-6">
            <h2>Něco o nás</h2>
            <hr className="divider" />
            <p>
              YachtHub je centrem pro všechny jachtaře a námořníky. Takový
              přístav v kyberprostoru, kde seženeš všechno potřebné pro své
              plavby.
            </p>
          </div>
        </div>
      </div>
    </>
  );
};
