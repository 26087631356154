import { useState, createContext, useContext, useCallback } from "react";

interface MenuContextType {
  isMobileOpen: boolean;
  openMobileMenu: () => void;
  closeMobileMenu: () => void;
}

const _initialContextData: MenuContextType = {
  isMobileOpen: false,
  openMobileMenu: () => undefined,
  closeMobileMenu: () => undefined,
};

export const MenuContext = createContext<MenuContextType>(_initialContextData);

function MenuProvider(props: React.PropsWithChildren<unknown>) {
  const [isMobileOpen, setMobileOpen] = useState(false);

  const openMobileMenu = useCallback(() => {
    setMobileOpen(true);
  }, []);

  const closeMobileMenu = useCallback(() => {
    setMobileOpen(false);
  }, []);

  return (
    <MenuContext.Provider
      value={{ isMobileOpen, openMobileMenu, closeMobileMenu }}
    >
      {props.children}
    </MenuContext.Provider>
  );
}

export default MenuProvider;

export const useMenu = () => {
  return useContext(MenuContext);
};
