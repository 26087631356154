import { BoatEquipment } from "../../types/entities/Equipment.types";

interface Props {
  equipment: BoatEquipment[];
  start: number;
  end: number;
}

export const BoatEquipmentList = ({ equipment, start, end }: Props) => {
  return (
    <ul className="column">
      {equipment.slice(start, end).map((eq: BoatEquipment) => {
        return (
          <li
            key={eq.equipment.id}
            className={`${eq.isIncluded ? " included" : " not-included"}`}
          >
            <div className="label">
              {eq.equipment.name}{" "}
              {eq.equipment.note ? (
                <>
                  <i className="icon-info">
                    <div className="note">{eq.equipment.note}</div>
                  </i>
                </>
              ) : (
                <></>
              )}
            </div>
            <div className={`value`}>
              {eq.isIncluded ? (
                <i className="icon-check_medium"></i>
              ) : (
                <>none</>
              )}
            </div>
          </li>
        );
      })}
    </ul>
  );
};
