import { Helmet } from "react-helmet";
import { WelcomeContainer } from "../Home/WelcomeContainer";
import "./iframe.styles.scss";

export const IFrameHomePageFilter = () => {
  return (
    <>
      <Helmet>
        <title>YachtHub | Pronájem lodí po celé Evropě</title>
      </Helmet>

      <div className="home-page">
        <WelcomeContainer />
      </div>
    </>
  );
};
