import { useCallback, useEffect, useState } from "react";
import RangeSlider from "react-range-slider-input";
import "./slider.styles.scss";
import "react-range-slider-input/dist/style.css";

interface Props {
  title: string;
  min: number;
  max: number;
  unit?: string;
  disableLocaleString?: boolean;
  disableMinPlus?: boolean;
  disableMaxPlus?: boolean;
  defaultValues?: [number, number];
  onChange: (value: [number, number]) => void;
}

export const Slider = ({
  title,
  min,
  max,
  unit,
  disableLocaleString,
  disableMinPlus,
  disableMaxPlus,
  defaultValues,
  onChange,
}: Props) => {
  const [values, setValues] = useState<[number, number]>(
    defaultValues || [min, max]
  );

  const handleOnInput = useCallback((val: [number, number]) => {
    setValues(val);
  }, []);

  const handleOnThumbDragEnd = useCallback(() => {
    onChange(values);
  }, [values]);

  useEffect(() => {
    if (defaultValues) setValues(defaultValues);
  }, [defaultValues]);

  return (
    <div className="slider-container">
      <div className="label">{title}</div>
      <RangeSlider
        className="slider"
        value={values}
        onInput={handleOnInput}
        min={min}
        max={max}
        onThumbDragEnd={handleOnThumbDragEnd}
        rangeSlideDisabled
      />
      <div className="values">
        <div className="from">{`${
          disableLocaleString ? values[0] : values[0].toLocaleString()
        }${values[0] === min && !disableMinPlus ? " a méně" : ""}${
          unit ? ` ${unit}` : ""
        }`}</div>
        <div className="to">{`${
          disableLocaleString ? values[1] : values[1].toLocaleString()
        }${values[1] === max && !disableMaxPlus ? " +" : ""}${
          unit ? ` ${unit}` : ""
        }`}</div>
      </div>
    </div>
  );
};
