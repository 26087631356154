import moment, { Moment } from "moment";
import { useCallback, useMemo, useState } from "react";
import { MonthContainer } from "./MonthContainer";
import { Button } from "../../Buttons/Button";

export interface DateSelection {
  from: Moment | null;
  to: Moment | null;
}

interface Props {
  defaultSelection: DateSelection;
  calendarDisplayLeft?: boolean;
  isMobile: boolean;
  onSelect: (
    dateSelection: DateSelection,
    closeCalendar: boolean,
    triggerOnChange: boolean
  ) => void;
}

export const Calendar = ({
  onSelect,
  calendarDisplayLeft,
  isMobile,
  defaultSelection,
}: Props) => {
  const [currentMonth, setCurrentMonth] = useState<Moment>(moment());
  const [selection, setSelection] = useState<DateSelection>(defaultSelection);
  const today = useMemo(() => {
    return moment();
  }, []);

  //#region handlers
  const handlePreviousMonth = useCallback(() => {
    const previousMonth = moment(currentMonth).add(-1, "month");
    if (previousMonth.isBefore(today, "month")) return;

    setCurrentMonth(previousMonth);
  }, [currentMonth]);

  const handleNextMonth = useCallback(() => {
    setCurrentMonth(moment(currentMonth).add(1, "month"));
  }, [currentMonth]);

  const handleSelectDay = useCallback(
    (day: Moment) => {
      if (!selection.from || (selection.from && selection.to)) {
        setSelection({
          from: day,
          to: null,
        });
        return;
      }

      let _selection: DateSelection = {
        ...selection,
        to: day,
      };

      if (day.isBefore(selection.from)) {
        _selection = {
          from: day,
          to: null,
        };
      }

      setSelection(_selection);
      onSelect(_selection, false, true);
    },
    [selection]
  );

  const handleClearSelection = useCallback(() => {
    const emptySelection = {
      from: null,
      to: null,
    };

    setSelection(emptySelection);
    onSelect(emptySelection, false, true);
  }, []);

  const handleConfirmSelection = useCallback(() => {
    onSelect(selection, true, true);
  }, [selection]);
  //#endregion

  if (!isMobile)
    return (
      <div className={`calendar${calendarDisplayLeft ? " display-left" : ""}`}>
        <button
          type="button"
          title="Previous month"
          className="navigation left"
          onClick={handlePreviousMonth}
        >
          <i className="icon-arrow_m"></i>
        </button>
        <button
          type="button"
          title="Next month"
          className="navigation right"
          onClick={handleNextMonth}
        >
          <i className="icon-arrow_m"></i>
        </button>
        <div className="months">
          <div className="previous-month">
            <MonthContainer
              month={currentMonth}
              selection={selection}
              onDaySelect={handleSelectDay}
            />
          </div>
          <div className="next-month">
            <MonthContainer
              month={moment(currentMonth).add(1, `months`)}
              selection={selection}
              onDaySelect={handleSelectDay}
            />
          </div>
        </div>
        <div className="buttons">
          <Button
            text="Smazat výběr"
            title="Smazat výběr"
            size="large"
            type="light"
            onClick={handleClearSelection}
          />
          <Button
            text="Potvrdit"
            title="Potvrdit"
            size="large"
            onClick={handleConfirmSelection}
          />
        </div>
      </div>
    );

  // **** MOBILE ****
  return (
    <>
      <div className="mobile">
        <div className={`calendar`}>
          <div className="months">
            <div className="month">
              <MonthContainer
                month={currentMonth}
                selection={selection}
                onDaySelect={handleSelectDay}
              />
            </div>
            {Array(16)
              .fill(1)
              .map((_monthNumber, key) => (
                <div key={key} className="month">
                  <MonthContainer
                    month={moment(currentMonth).add(key + 1, `months`)}
                    selection={selection}
                    onDaySelect={handleSelectDay}
                  />
                </div>
              ))}
          </div>
        </div>
      </div>
      <div className="bottom-bar">
        <Button
          text="Smazat výběr"
          title="Smazat výběr"
          size="large"
          type="light"
          onClick={handleClearSelection}
        />
        <Button
          customClass="mobile-submit"
          text="Potvrdit"
          title="Potvrdit"
          size="large"
          onClick={handleConfirmSelection}
        />
      </div>
    </>
  );
};
