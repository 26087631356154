import "./tag.styles.scss";

interface Props {
  title?: string;
  icon?: string;
}

export const Tag = ({ title, icon }: Props) => {
  return (
    <span className="tag">
      <i className={icon}></i>
      {title}
    </span>
  );
};
